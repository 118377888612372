import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { User } from 'app/core/user/user.types';
import { DisplayCustomerService } from './display-customer.service';

@Component({
    selector: 'display-customer',
    templateUrl: './display-customer.component.html',
    styleUrls: ['./display-customer.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class DisplayCustomerComponent {
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    @Input() user: User;
    @Input() withoutIcon: boolean = false;
    pseudonyme: string = null;
    /**
     * Constructor
     */
    constructor(private _displayCustomerService: DisplayCustomerService) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    ngOnInit(): void {
        this.pseudonyme = this._displayCustomerService.formatPseudonyme(
            this.user
        );
    }

    ngOnDestroy() {
        this._unsubscribeAll.complete();
    }
}
