<ng-container *ngIf="user.is_laboratory">
    <ng-container [ngSwitch]="user.type">
        <ng-container *ngSwitchCase="'wholesaler_pharma'">
            <div class="flex content-center items-center">
                <img src="assets/images/pngico/0063.png" alt="icone" class="mr-2" style="width: 30px; height: auto"
                    *ngIf="!withoutIcon" /><strong class="truncate overflow-hidden"
                    [matTooltip]="'type-labo-pharma' | transloco" matTooltipPosition="right">{{pseudonyme}}</strong>
            </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <div class="flex content-center items-center">
                <img src="assets/images/pngico/0064.png" alt="icone" class="mr-2" style="width: 30px; height: auto"
                    *ngIf="!withoutIcon" /><strong class="truncate overflow-hidden"
                    [matTooltip]="'type-labo-nopharma' | transloco" matTooltipPosition="right">{{pseudonyme}}</strong>
            </div>

        </ng-container>

    </ng-container>


</ng-container>
<ng-container *ngIf="!user.is_laboratory">
    <ng-container [ngSwitch]="user.type">
        <ng-container *ngSwitchCase="'pharma'">
            <div class="flex content-center items-center">
                <img src="assets/images/pngico/0065.png" alt="icone" class="mr-2" style="width: 30px; height: auto"
                    *ngIf="!withoutIcon" /><strong class="truncate overflow-hidden"
                    [matTooltip]="'type-pharma' | transloco" matTooltipPosition="right">{{pseudonyme}}</strong>
            </div>

        </ng-container>
        <ng-container *ngSwitchCase="'wholesaler_pharma'">
            <div class="flex content-center items-center">
                <img src="assets/images/pngico/0062.png" alt="icone" class="mr-2" style="width: 40px; height: auto"
                    *ngIf="!withoutIcon" /><strong class="truncate overflow-hidden"
                    [matTooltip]="'type-wholesaler-pharma' | transloco"
                    matTooltipPosition="right">{{pseudonyme}}</strong>
            </div>

        </ng-container>
        <ng-container *ngSwitchDefault>
            <div class="flex content-center items-center"><img src="assets/images/pngico/0061.png" alt="icone"
                    class="mr-2" style="width: 40px; height: auto" *ngIf="!withoutIcon" /><strong
                    class="truncate overflow-hidden" [matTooltip]="'type-wholesaler-nopharma' | transloco"
                    matTooltipPosition="right">{{pseudonyme}}</strong>
            </div>

        </ng-container>
    </ng-container>
</ng-container>
