import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    catchError,
    Observable,
    of,
    switchMap,
    throwError,
    delay,
    BehaviorSubject,
} from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { Router } from '@angular/router';
import {
    IAuthentication,
    IHttpAuthentication,
    IPermission,
} from './auth.types';
import { IFormSignup, TUserAccountType } from '../global.types';
import { AlertService } from '../alerts/alert.service';
import { environment } from 'environments/environment';

@Injectable()
export class AuthService {
    private _authenticated: boolean = false;
    private _phoneverification: boolean = false;
    private _user: TUserAccountType;
    private _permissions: BehaviorSubject<IPermission | null> =
        new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private _router: Router,
        private _alertService: AlertService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }
    get refreshToken(): string {
        return localStorage.getItem('refreshToken') ?? '';
    }
    get permissions$(): Observable<IPermission> {
        return this._permissions.asObservable();
    }
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }
    set refreshToken(token: string) {
        localStorage.setItem('refreshToken', token);
    }

    set phoneVerification(verification: boolean) {
        this._phoneverification = verification;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post(
            environment.apiUrl + '/v1/oauth/forgot-password',
            { email }
        );
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(
        password: string,
        password_confirmation: string,
        token: string,
        pin: string
    ): Observable<any> {
        return this._httpClient.post(
            environment.apiUrl + '/v1/oauth/reset-password',
            {
                password: btoa(password),
                password_confirmation: btoa(password_confirmation),
                token: btoa(token),
                pin: pin,
            }
        );
    }

    setPermissions(permissions: IPermission): void {
        this._permissions.next(permissions);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(
        credentials: { login: string; password: string },
        code: string = null
    ): Observable<IHttpAuthentication> {
        if (this._authenticated) {
            return throwError('Vous êtes déjà connecté');
        }
        return this._httpClient
            .post(environment.apiUrl + '/v1/oauth/sign-in', {
                payload: AuthUtils.fullAesEncode({
                    login: credentials.login,
                    password: credentials.password,
                    code: code,
                }),
                f2a: code ? true : false,
            })
            .pipe(
                switchMap((response: string) => {
                    const res: IHttpAuthentication =
                        AuthUtils.fullAesDecode(response);

                    if (res.success) {
                        if (!res.data.fa_required) {
                            this.accessToken = res.data.access_token;
                            this.refreshToken = res.data.refresh_token;
                            this._authenticated = true;
                            this._phoneverification =
                                res.data.phoneverification;
                            this._userService.user = res.data.user;
                            this._user = res.data.user.type;
                        }
                    } else {
                        this._alertService.liveAlert({
                            appearance: 'soft',
                            dismissed: false,
                            showIcon: true,
                            type: 'warning',
                            position: 'bottom-center',
                            message: res.message,
                            clearTime: 5000,
                        });
                    }

                    return of(res);
                })
            );
    }

    /**
     * Verify 2FA for authentication
     *
     * Verifies the two-factor authentication code for a user and sets authentication tokens if valid.
     *
     * @param user_hash - The hashed user ID
     * @param login - The user's login
     * @param password - The user's password
     * @param code - The two-factor authentication code
     *
     * @returns Observable with authentication response
     */
    verify2FA(
        user_hash: string,
        login: string,
        password: string,
        code: string
    ): Observable<any> {
        return this._httpClient
            .post(environment.apiUrl + '/v1/oauth/verify-2fa', {
                payload: AuthUtils.fullAesEncode({
                    user_hash,
                    code,
                    login,
                    password,
                }),
            })
            .pipe(
                switchMap((response: string) => {
                    const data: IAuthentication =
                        AuthUtils.fullAesDecode(response);

                    this.accessToken = data.access_token;
                    this.refreshToken = data.refresh_token;
                    this._authenticated = true;
                    this._phoneverification = data.phoneverification;
                    this._userService.user = data.user;
                    this._user = data.user.type;
                    return of(data);
                })
            );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token
        return this._httpClient
            .post(environment.apiUrl + '/v1/oauth/sign-in-with-token', {
                accessToken: this.accessToken,
            })
            .pipe(
                catchError(() =>
                    // Return false
                    of(false)
                ),
                switchMap((response: any) => {
                    // Replace the access token with the new one if it's available on
                    // the response object.
                    //
                    // This is an added optional step for better security. Once you sign
                    // in using the token, you should generate a new one on the server
                    // side and attach it to the response object. Then the following
                    // piece of code can replace the token with the refreshed one.
                    if (response.accessToken) {
                        this.accessToken = response.accessToken;
                    }

                    // Set the authenticated flag to true
                    this._authenticated = true;

                    // Store the user on the user service
                    this._userService.user = response.user;

                    // Return true
                    return of(true);
                })
            );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        return this._httpClient
            .get(environment.apiUrl + '/v1/oauth/logout')
            .pipe(
                switchMap((response: any) => {
                    // Remove the access token from the local storage
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('refreshToken');
                    // Set the authenticated flag to false
                    this._authenticated = false;
                    // Return a new observable with the response
                    return of(true);
                })
            );
    }

    secureOut(): void {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        // Remove the access token from the local storage
        localStorage.removeItem('refreshToken');
        // Set the authenticated flag to false
        this._authenticated = false;

        this._router.navigateByUrl('sign-out');
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(
        values: IFormSignup,
        subscription: PushSubscription = null
    ): Observable<any> {
        return this._httpClient.post(environment.apiUrl + '/v1/oauth/sign-up', {
            ...values.step1,
            ...values.step2,
            ...values.step3,
            ...values.step4,
            subscription: subscription,
        });
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: {
        email: string;
        password: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated || this.refreshToken) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken && !this.refreshToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    /**
     * Check the phone verification
     */

    checkPhone() {
        // Check if the phone verified
        this._userService.user$.subscribe((user) => {
            this._user = user.type;
        });
        return this._user != 'pharma' ? true : this._phoneverification;
    }

    refreshTokenMethod(): Observable<any> {
        return this._httpClient
            .post(environment.apiUrl + '/v1/oauth/refresh-token', {
                refreshToken: this.refreshToken,
            })
            .pipe(delay(0));
    }

    /**
     * Check Module permission
     *
     * @param credentials
     */
    checkPermission(redirectURL: string, auth: boolean): Observable<any> {
        if (!auth) {
            return of(true);
        } else {
            return this._httpClient
                .get(environment.apiUrl + '/v1/oauth/permission', {
                    params: {
                        slug: AuthUtils.aesEncode(redirectURL),
                    },
                })
                .pipe(
                    catchError((e) => {
                        return of(false);
                    }),
                    switchMap((response: string) => {
                        const permissions: IPermission = JSON.parse(
                            AuthUtils.aesDecode(response)
                        );
                        this.setPermissions(permissions);
                        return of(permissions?.access ?? false);
                    })
                );
        }
    }
}
