<div class="expepharma-alert-container" *ngIf="!dismissible || dismissible && !dismissed" [@zoomIn]="!dismissed"
    [@zoomOut]="!dismissed">

    <!-- Border -->
    <div class="expepharma-alert-border" *ngIf="appearance === 'border'"></div>

    <!-- Icon -->
    <div class="expepharma-alert-icon" *ngIf="showIcon">

        <!-- Custom icon -->
        <div class="expepharma-alert-custom-icon">
            <ng-content select="[expepharmaAlertIcon]"></ng-content>
        </div>

        <!-- Default icons -->
        <div class="expepharma-alert-default-icon">

            <mat-icon *ngIf="type === 'primary'" [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>

            <mat-icon *ngIf="type === 'accent'" [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>

            <mat-icon *ngIf="type === 'warn'" [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>

            <mat-icon *ngIf="type === 'basic'" [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>

            <mat-icon *ngIf="type === 'info'" [svgIcon]="'heroicons_solid:information-circle'"></mat-icon>

            <mat-icon *ngIf="type === 'success'" [svgIcon]="'heroicons_solid:check-circle'"></mat-icon>

            <mat-icon *ngIf="type === 'warning'" [svgIcon]="'heroicons_solid:exclamation'"></mat-icon>

            <mat-icon *ngIf="type === 'error'" [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>

        </div>

    </div>

    <!-- Content -->
    <div class="expepharma-alert-content">

        <div class="expepharma-alert-title">
            <ng-content select="[expepharmaAlertTitle]"></ng-content>
        </div>

        <div class="expepharma-alert-message">
            <ng-content></ng-content>
        </div>

    </div>

    <!-- Dismiss button -->
    <button class="expepharma-alert-dismiss-button" mat-icon-button (click)="dismiss()">
        <mat-icon [svgIcon]="'heroicons_solid:x'"></mat-icon>
    </button>

</div>
