import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, take } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class VersionService {
    constructor(private _httpClient: HttpClient) {}

    public checkVersion(): Observable<string> {
        return this._httpClient.get('/assets/version.json').pipe(
            map((res: any) => {
                return res.version || null;
            })
        );
    }
}
