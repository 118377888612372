<div class="flex flex-col max-w-240 max-h-screen -m-6">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{"validation-phone-account-title-v2" | transloco}}</div>
    </div>

    <div class="w-full overflow-y-auto p-10">

        <div class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto" *ngIf="isValidated">
            <div class="flex mb-4">
                <div class="w-full animation-ctn flex justify-center">
                    <div class="icon--order-success">
                        <svg xmlns="http://www.w3.org/2000/svg" width="154px" height="154px">
                            <g fill="none" stroke="#5a7d7c" stroke-width="2">
                                <circle cx="77" cy="77" r="72"
                                    style="stroke-dasharray:480px, 480px; stroke-dashoffset: 960px;"></circle>
                                <circle id="colored" fill="#5a7d7c" cx="77" cy="77" r="72"
                                    style="stroke-dasharray:480px, 480px; stroke-dashoffset: 960px;"></circle>
                                <polyline class="st0" stroke="#fff" stroke-width="10"
                                    points="43.5,77.8 63.7,97.9 112.2,49.4 "
                                    style="stroke-dasharray:100px, 100px; stroke-dashoffset: 200px;" />
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="flex mb-4">
                <div class="w-full text-center font-bold text-xl">
                    {{"validated-your-account-sub-text-v2" | transloco}}
                </div>
            </div>

            <div class="flex">
                <button
                    class="mat-mdc-button expepharma-mat-button-large w-full transition duration-150 ease-in-out hover:scale-95 hover:drop-shadow-xl hover:bg-[#12161a] bg-[#232c33] text-white"
                    mat-flat-button [color]="'primary'" (click)="closeModal()">
                    {{"go-to-navigation" | transloco}}

                </button>
            </div>
        </div>

        <!-- Forgot password form -->
        <form class="mt-2" [formGroup]="phoneverificateForm" #phoneverificateNgForm="ngForm" *ngIf="!isValidated">
            <p>{{"validated-your-account-code" | transloco}}</p>

            <div class="code-inputs-container">
                <ng-container *ngFor="let field of fields; let i = index">
                    <input type="text" [formControlName]="'digit' + i" maxlength="1" (keyup)="onKeyUp($event, i)"
                        (keydown)="onKeyDown($event, i)" [id]="'digit' + i" #input
                        class="code-input-field shadow-md	 hover:shadow-xl	">
                </ng-container>
            </div>
            <button
                class="mat-mdc-button expepharma-mat-button-large w-full transition duration-150 ease-in-out hover:scale-95 hover:drop-shadow-xl hover:bg-[#425c5b] bg-[#5a7d7c] text-white mb-2 mt-4"
                mat-flat-button [color]="'primary'"
                [disabled]="phoneverificateForm.disabled || !phoneverificateForm.valid"
                (click)="sendCodeVerification()">
                <span *ngIf="!phoneverificateForm.disabled">
                    {{"send-phone-verification" | transloco}}
                </span>
                <mat-progress-spinner *ngIf="phoneverificateForm.disabled" [diameter]="24" [mode]="'indeterminate'">
                </mat-progress-spinner>
            </button>

            <button
                class="mat-mdc-button expepharma-mat-button-large w-full transition duration-150 ease-in-out hover:scale-95 hover:drop-shadow-xl hover:bg-[#12161a] bg-[#232c33] text-white mb-2"
                mat-flat-button [color]="'primary'" [disabled]="buttonSpinner || buttonDisabled"
                (click)="recallVerification()">
                <span *ngIf="!buttonSpinner">
                    <ng-container *ngIf="!buttonDisabled">
                        <ng-container *ngIf="alreadyAsk">
                            {{"recall-verification-page" | transloco}}
                        </ng-container>
                        <ng-container *ngIf="!alreadyAsk">
                            {{"call-verification-page" | transloco}}
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="buttonDisabled">
                        {{ 'recall-in-seconds' | transloco: {SEC: countdown} }}
                    </ng-container>
                </span>
                <mat-progress-spinner *ngIf="buttonSpinner" [diameter]="24" [mode]="'indeterminate'">
                </mat-progress-spinner>

            </button>
        </form>


        <!--
                <button
            class="mat-mdc-button expepharma-mat-button-large w-full transition duration-150 ease-in-out hover:scale-95 hover:drop-shadow-xl hover:bg-[#69383d] bg-[#904e55] text-white"
            type="button" mat-flat-button (click)="signOut()">
            {{ 'logout-page' | transloco }}
        </button>

        -->


    </div>
</div>
