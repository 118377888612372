import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import {
    BehaviorSubject,
    catchError,
    EMPTY,
    filter,
    Observable,
    Subject,
    switchMap,
    take,
    takeUntil,
    throwError,
} from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import {
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { AlertService } from 'app/core/alerts/alert.service';
import { IApiMethods, IPermission } from 'app/core/auth/auth.types';
import { TranslocoService } from '@ngneat/transloco';
import { Router } from '@angular/router';
import { ExpepharmaLoadingService } from '@expepharma/services/loading';
import { HistoryService } from '../history/history.service';
import { environment } from 'environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    private permissions: IPermission | null = null;
    private isRefreshing: boolean = false;
    private refreshTokenSubject: BehaviorSubject<any> =
        new BehaviorSubject<any>(null);
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private activeLang: any;
    private headerLangueKey: string = 'set-locale';
    private headerPositionKey: string = 'set-Position';
    private currentSlug: string = '/';
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private _alertService: AlertService,
        private _translocoService: TranslocoService,
        private _router: Router,
        private _expepharmaLoadingService: ExpepharmaLoadingService,
        private _historyService: HistoryService
    ) {
        this._authService.permissions$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((permissions: IPermission) => {
                this.permissions = permissions;
            });
        this._translocoService.langChanges$.subscribe((activeLang) => {
            this.activeLang = activeLang;
        });
        this._historyService.currentSlug$.subscribe((e) => {
            this.currentSlug = e;
        });
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<Object>> {
        let authReq: HttpRequest<any> = req;

        authReq = this.addLanguageHeader(authReq, this.activeLang);

        const token = this._authService.accessToken;
        if (token !== null && token !== '') {
            authReq = this.addTokenHeader(req, token);
        }

        let option = null;
        if (authReq.headers.keys().find((e) => e === 'option')) {
            option = authReq.headers.get('option');
        }

        const method: IApiMethods = <any>req.method.toLowerCase();

        if (
            !AuthUtils.excludingCheckUrl(req.url) &&
            !AuthUtils.permissionVerbs(method, this.permissions, option)
        ) {
            if (token !== null && token !== '') {
                this._alertService
                    .newAlert({
                        appearance: 'soft',
                        dismissed: false,
                        showIcon: true,
                        type: 'warning',
                        position: 'bottom-center',
                        message: 'Votre accès ne vous permet pas cette action',
                    })
                    .subscribe();
            } else {
                this._router.navigate(['sign-in']).then(() => {
                    setTimeout(
                        () => this._expepharmaLoadingService.hide(),
                        100
                    );
                });
            }

            return EMPTY;
            // return next.handle(req).pipe(
            //     finalize(() => {
            //         this._router.navigate(['sign-in']);
            //     })
            // );
        } else {
            return next.handle(authReq).pipe(
                catchError((error) => {
                    if (
                        error instanceof HttpErrorResponse &&
                        !authReq.url.includes(
                            environment.apiUrl + '/v1/oauth/sign-in'
                        ) &&
                        error.status === 401
                    ) {
                        if (
                            authReq.url.includes(
                                environment.apiUrl + '/v1/oauth/refresh-token'
                            )
                        ) {
                            return throwError(() => new Error(error.message));
                        }
                        return this.handle401Error(authReq, next);
                    }
                    this._alertService
                        .newAlert({
                            appearance: 'soft',
                            dismissed: false,
                            showIcon: true,
                            type: 'warn',
                            position: 'top-center',
                            message: this.manageErrorDisplay(error),
                        })
                        .subscribe();

                    return throwError(() => new Error(error.message));
                })
            );
        }
    }

    private manageErrorDisplay(error) {
        if (error?.error?.error) {
            return error?.error?.error;
        } else if (error?.error) {
            return AuthUtils.aesDecode(error.error);
        } else {
            return 'Une erreur est survenue pendant le chargement. Veuillez essayer de nouveau dans quelques instants';
        }
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            const token = this._authService.accessToken;

            if (token)
                return this._authService.refreshTokenMethod().pipe(
                    switchMap((res: any) => {
                        this.isRefreshing = false;
                        localStorage.setItem('accessToken', res.access_token);
                        localStorage.setItem('refreshToken', res.refresh_token);
                        this._authService.refreshToken = res.refresh_token;
                        this._authService.accessToken = res.access_token;
                        this.refreshTokenSubject.next(res.access_token);
                        return next.handle(
                            this.addTokenHeader(request, res.access_token)
                        );
                    }),
                    catchError((err) => {
                        this._alertService
                            .newAlert({
                                appearance: 'soft',
                                dismissed: false,
                                showIcon: true,
                                type: 'info',
                                position: 'top-center',
                                message:
                                    'Vous avez été déconnecté de la plateforme',
                            })
                            .subscribe(() => {
                                this.isRefreshing = false;
                                this._authService.secureOut();
                            });
                        return throwError(() => new Error(err));
                    })
                );
        }

        return this.refreshTokenSubject.pipe(
            filter((token) => token !== null),
            take(1),
            switchMap((token) =>
                next.handle(this.addTokenHeader(request, token))
            )
        );
    }
    private addTokenHeader(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                [this.headerLangueKey]: this.activeLang,
                Authorization: 'Bearer ' + token,
                [this.headerPositionKey]: AuthUtils.checkUrl(this.currentSlug),
            },
        });
    }

    private addLanguageHeader(request: HttpRequest<any>, currentLangue) {
        return request.clone({
            headers: request.headers.set(this.headerLangueKey, currentLangue),
        });
    }
}
