import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ExpepharmaDrawerModule } from '@expepharma/components/drawer';
import { SwitchComponent } from 'app/layout/common/switch/switch.component';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from 'app/shared/shared.module';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { TranslocoModule } from '@ngneat/transloco';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
    declarations: [SwitchComponent],
    imports: [
        CommonModule,
        RouterModule,
        MatIconModule,
        MatTooltipModule,
        ExpepharmaDrawerModule,
        MatButtonModule,
        MatCheckboxModule,
        SharedModule,
        MatInputModule,
        TranslocoModule,
        MatRadioModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
    ],
    exports: [SwitchComponent],
})
export class SwitchModule {}
