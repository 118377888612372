import { NgModule } from '@angular/core';
import { ExpepharmaPlatformService } from '@expepharma/services/platform/platform.service';

@NgModule({
    providers: [ExpepharmaPlatformService],
})
export class ExpepharmaPlatformModule {
    /**
     * Constructor
     */
    constructor(
        private _expepharmaPlatformService: ExpepharmaPlatformService
    ) {}
}
