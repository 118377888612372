import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree,
} from '@angular/router';
import {
    combineLatest,
    delay,
    forkJoin,
    map,
    Observable,
    of,
    switchMap,
} from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { HistoryService } from 'app/core/history/history.service';
import { IPermission } from '../auth.types';
import { UserService } from 'app/core/user/user.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
    public isCheckAuthGuard: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private _router: Router,
        private _historyService: HistoryService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.isCheckAuthGuard) {
            this._historyService.updateCurrentSlug(state.url);
            this.isCheckAuthGuard = true;
            return this._check(state.url);
        } else {
            return true;
        }
    }

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if (!this.isCheckAuthGuard) {
            this.isCheckAuthGuard = true;
            let redirectUrl = '/';
            if (
                state.url !== '/sign-out' &&
                state.url !== '/phone-verification'
            ) {
                redirectUrl = state.url;
            }

            this._historyService.updateCurrentSlug(redirectUrl);
            return this._check(redirectUrl);
        } else {
            return true;
        }
    }

    /**
     * Can load
     *
     * @param route
     * @param segments
     */
    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean> | Promise<boolean> | boolean {
        this._historyService.updateCurrentSlug('/');
        return this._check('/');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param segments
     * @private
     */
    private _check(redirectURL: string): Observable<any> {
        const getLastestRoute = this._historyService
            .getHistory()
            .filter((e) => !e.includes('sign-out') && !e.includes('sign-in'));

        const checkAuth = this._authService.check();
        // const checkPhone = this._authService.checkPhone();
        const checkPerm = checkAuth.pipe(
            switchMap((authenticated): Observable<boolean> => {
                return this._authService.checkPermission(
                    redirectURL,
                    authenticated
                );
            })
        );

        return combineLatest([checkAuth, checkPerm]).pipe(
            map(([checkAuth, checkPerm]) => {
                setTimeout(() => {
                    this.isCheckAuthGuard = false;
                }, 10);
                if (!checkAuth || !checkPerm) {
                    redirectURL =
                        getLastestRoute.length > 0
                            ? getLastestRoute[0]
                            : redirectURL;
                    this._router.navigate(
                        [!checkAuth ? 'sign-in' : 'error/unauthorized'],
                        {
                            queryParams: { redirectURL },
                        }
                    );

                    return of(false);
                } else {
                    // if (!checkPhonePipe) {
                    //     this._router.navigate(['phone-verification']);
                    //     return of(false);
                    // }
                    return of(true);
                }
            })
        );
    }
}
