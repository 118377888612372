import { Injectable } from '@angular/core';
import { User } from 'app/core/user/user.types';

@Injectable({
    providedIn: 'root',
})
export class DisplayCustomerService {
    constructor() {}

    formatPseudonyme(user: User): string {
        if (!user) {
            return 'USER_ANONYME';
        }

        let firstName = user.first_name?.toLowerCase() || '';
        if (firstName.length > 8) {
            firstName = firstName.substring(0, 8) + '...';
        } else {
            firstName = firstName.substring(0, 8) + '.';
        }
        firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);

        let lastName = user.last_name?.toLowerCase() || '';
        const lastNameInitial = lastName.charAt(0).toUpperCase();

        if (user.is_laboratory) {
            return user.legal?.company || 'ANONYMOUS';
        } else {
            switch (user.type) {
                case 'wholesaler_pharma':
                case 'wholesaler_nopharma':
                    return `${firstName}${lastNameInitial}`.trim();
                default:
                    return user.pseudonyme || 'ANONYMOUS';
            }
        }
    }
}
