import type { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { IContinent, ICountry } from 'app/core/global.types';

export const countriesList: ICountry[] = [
    {
        name: 'Afghanistan',
        code: 'AF',
        continent: 'AS',
    },
    {
        name: 'land Islands',
        code: 'AX',
        continent: 'EU',
    },
    {
        name: 'Albania',
        code: 'AL',
        continent: 'EU',
    },
    {
        name: 'Algeria',
        code: 'DZ',
        continent: 'AF',
    },
    {
        name: 'American Samoa',
        code: 'AS',
        continent: 'OC',
    },
    {
        name: 'AndorrA',
        code: 'AD',
        continent: 'EU',
    },
    {
        name: 'Angola',
        code: 'AO',
        continent: 'AF',
    },
    {
        name: 'Anguilla',
        code: 'AI',
        continent: 'NA',
    },
    {
        name: 'Antarctica',
        code: 'AQ',
        continent: 'AN',
    },
    {
        name: 'Antigua and Barbuda',
        code: 'AG',
        continent: 'NA',
    },
    {
        name: 'Argentina',
        code: 'AR',
        continent: 'SA',
    },
    {
        name: 'Armenia',
        code: 'AM',
        continent: 'AS',
    },
    {
        name: 'Aruba',
        code: 'AW',
        continent: 'NA',
    },
    {
        name: 'Australia',
        code: 'AU',
        continent: 'OC',
    },
    {
        name: 'Austria',
        code: 'AT',
        continent: 'EU',
    },
    {
        name: 'Azerbaijan',
        code: 'AZ',
        continent: 'AS',
    },
    {
        name: 'Bahamas',
        code: 'BS',
        continent: 'NA',
    },
    {
        name: 'Bahrain',
        code: 'BH',
        continent: 'AS',
    },
    {
        name: 'Bangladesh',
        code: 'BD',
        continent: 'AS',
    },
    {
        name: 'Barbados',
        code: 'BB',
        continent: 'NA',
    },
    {
        name: 'Belarus',
        code: 'BY',
        continent: 'EU',
    },
    {
        name: 'Belgium',
        code: 'BE',
        continent: 'EU',
        priority: true,
    },
    {
        name: 'Belize',
        code: 'BZ',
        continent: 'NA',
    },
    {
        name: 'Benin',
        code: 'BJ',
        continent: 'AF',
    },
    {
        name: 'Bermuda',
        code: 'BM',
        continent: 'NA',
    },
    {
        name: 'Bhutan',
        code: 'BT',
        continent: 'AS',
    },
    {
        name: 'Bolivia',
        code: 'BO',
        continent: 'SA',
    },
    {
        name: 'Bosnia and Herzegovina',
        code: 'BA',
        continent: 'EU',
    },
    {
        name: 'Botswana',
        code: 'BW',
        continent: 'AF',
    },
    {
        name: 'Bouvet Island',
        code: 'BV',
        continent: 'AN',
    },
    {
        name: 'Brazil',
        code: 'BR',
        continent: 'SA',
    },
    {
        name: 'British Indian Ocean Territory',
        code: 'IO',
        continent: 'AS',
    },
    {
        name: 'Brunei Darussalam',
        code: 'BN',
        continent: 'AS',
    },
    {
        name: 'Bulgaria',
        code: 'BG',
        continent: 'EU',
    },
    {
        name: 'Burkina Faso',
        code: 'BF',
        continent: 'AF',
    },
    {
        name: 'Burundi',
        code: 'BI',
        continent: 'AF',
    },
    {
        name: 'Cambodia',
        code: 'KH',
        continent: 'AS',
    },
    {
        name: 'Cameroon',
        code: 'CM',
        continent: 'AF',
    },
    {
        name: 'Canada',
        code: 'CA',
        continent: 'NA',
    },
    {
        name: 'Cape Verde',
        code: 'CV',
        continent: 'AF',
    },
    {
        name: 'Cayman Islands',
        code: 'KY',
        continent: 'NA',
    },
    {
        name: 'Central African Republic',
        code: 'CF',
        continent: 'AF',
    },
    {
        name: 'Chad',
        code: 'TD',
        continent: 'AF',
    },
    {
        name: 'Chile',
        code: 'CL',
        continent: 'SA',
    },
    {
        name: 'China',
        code: 'CN',
        continent: 'AS',
    },
    {
        name: 'Christmas Island',
        code: 'CX',
        continent: 'AS',
    },
    {
        name: 'Cocos (Keeling) Islands',
        code: 'CC',
        continent: 'AS',
    },
    {
        name: 'Colombia',
        code: 'CO',
        continent: 'SA',
    },
    {
        name: 'Comoros',
        code: 'KM',
        continent: 'AF',
    },
    {
        name: 'Congo',
        code: 'CG',
        continent: 'AF',
    },
    {
        name: 'Congo, The Democratic Republic of the',
        code: 'CD',
        continent: 'AF',
    },
    {
        name: 'Cook Islands',
        code: 'CK',
        continent: 'OC',
    },
    {
        name: 'Costa Rica',
        code: 'CR',
        continent: 'NA',
    },
    {
        name: "Cote d'ivoire",
        code: 'CI',
        continent: 'AF',
    },
    {
        name: 'Croatia',
        code: 'HR',
        continent: 'EU',
    },
    {
        name: 'Cuba',
        code: 'CU',
        continent: 'NA',
    },
    {
        name: 'Cyprus',
        code: 'CY',
        continent: 'EU',
    },
    {
        name: 'Czech Republic',
        code: 'CZ',
        continent: 'EU',
    },
    {
        name: 'Denmark',
        code: 'DK',
        continent: 'EU',
    },
    {
        name: 'Djibouti',
        code: 'DJ',
        continent: 'AF',
    },
    {
        name: 'Dominica',
        code: 'DM',
        continent: 'NA',
    },
    {
        name: 'Dominican Republic',
        code: 'DO',
        continent: 'NA',
    },
    {
        name: 'Ecuador',
        code: 'EC',
        continent: 'SA',
    },
    {
        name: 'Egypt',
        code: 'EG',
        continent: 'AF',
    },
    {
        name: 'El Salvador',
        code: 'SV',
        continent: 'NA',
    },
    {
        name: 'Equatorial Guinea',
        code: 'GQ',
        continent: 'AF',
    },
    {
        name: 'Eritrea',
        code: 'ER',
        continent: 'AF',
    },
    {
        name: 'Estonia',
        code: 'EE',
        continent: 'EU',
    },
    {
        name: 'Ethiopia',
        code: 'ET',
        continent: 'AF',
    },
    {
        name: 'Falkland Islands (Malvinas)',
        code: 'FK',
        continent: 'SA',
    },
    {
        name: 'Faroe Islands',
        code: 'FO',
        continent: 'EU',
    },
    {
        name: 'Fiji',
        code: 'FJ',
        continent: 'OC',
    },
    {
        name: 'Finland',
        code: 'FI',
        continent: 'EU',
    },
    {
        name: 'France',
        code: 'FR',
        continent: 'EU',
        priority: true,
    },
    {
        name: 'French Guiana',
        code: 'GF',
        continent: 'SA',
    },
    {
        name: 'French Polynesia',
        code: 'PF',
        continent: 'OC',
    },
    {
        name: 'French Southern Territories',
        code: 'TF',
        continent: 'AN',
    },
    {
        name: 'Gabon',
        code: 'GA',
        continent: 'AF',
    },
    {
        name: 'Gambia',
        code: 'GM',
        continent: 'AF',
    },
    {
        name: 'Georgia',
        code: 'GE',
        continent: 'AS',
    },
    {
        name: 'Germany',
        code: 'DE',
        continent: 'EU',
        priority: true,
    },
    {
        name: 'Ghana',
        code: 'GH',
        continent: 'AF',
    },
    {
        name: 'Gibraltar',
        code: 'GI',
        continent: 'EU',
    },
    {
        name: 'Greece',
        code: 'GR',
        continent: 'EU',
    },
    {
        name: 'Greenland',
        code: 'GL',
        continent: 'NA',
    },
    {
        name: 'Grenada',
        code: 'GD',
        continent: 'NA',
    },
    {
        name: 'Guadeloupe',
        code: 'GP',
        continent: 'NA',
    },
    {
        name: 'Guam',
        code: 'GU',
        continent: 'OC',
    },
    {
        name: 'Guatemala',
        code: 'GT',
        continent: 'NA',
    },
    {
        name: 'Guernsey',
        code: 'GG',
        continent: 'EU',
    },
    {
        name: 'Guinea',
        code: 'GN',
        continent: 'AF',
    },
    {
        name: 'Guinea-Bissau',
        code: 'GW',
        continent: 'AF',
    },
    {
        name: 'Guyana',
        code: 'GY',
        continent: 'SA',
    },
    {
        name: 'Haiti',
        code: 'HT',
        continent: 'NA',
    },
    {
        name: 'Heard Island and Mcdonald Islands',
        code: 'HM',
        continent: 'AN',
    },
    {
        name: 'Holy See (Vatican City State)',
        code: 'VA',
        continent: 'EU',
    },
    {
        name: 'Honduras',
        code: 'HN',
        continent: 'NA',
    },
    {
        name: 'Hong Kong',
        code: 'HK',
        continent: 'AS',
    },
    {
        name: 'Hungary',
        code: 'HU',
        continent: 'EU',
    },
    {
        name: 'Iceland',
        code: 'IS',
        continent: 'EU',
    },
    {
        name: 'India',
        code: 'IN',
        continent: 'AS',
    },
    {
        name: 'Indonesia',
        code: 'ID',
        continent: 'AS',
    },
    {
        name: 'Iran, Islamic Republic Of',
        code: 'IR',
        continent: 'AS',
    },
    {
        name: 'Iraq',
        code: 'IQ',
        continent: 'AS',
    },
    {
        name: 'Ireland',
        code: 'IE',
        continent: 'EU',
    },
    {
        name: 'Isle of Man',
        code: 'IM',
        continent: 'EU',
    },
    {
        name: 'Israel',
        code: 'IL',
        continent: 'AS',
    },
    {
        name: 'Italy',
        code: 'IT',
        continent: 'EU',
        priority: true,
    },
    {
        name: 'Jamaica',
        code: 'JM',
        continent: 'NA',
    },
    {
        name: 'Japan',
        code: 'JP',
        continent: 'AS',
    },
    {
        name: 'Jersey',
        code: 'JE',
        continent: 'EU',
    },
    {
        name: 'Jordan',
        code: 'JO',
        continent: 'AS',
    },
    {
        name: 'Kazakhstan',
        code: 'KZ',
        continent: 'AS',
    },
    {
        name: 'Kenya',
        code: 'KE',
        continent: 'AF',
    },
    {
        name: 'Kiribati',
        code: 'KI',
        continent: 'OC',
    },
    {
        name: "Korea, Democratic People's Republic of",
        code: 'KP',
        continent: 'AS',
    },
    {
        name: 'Korea, Republic of',
        code: 'KR',
        continent: 'AS',
    },
    {
        name: 'Kuwait',
        code: 'KW',
        continent: 'AS',
    },
    {
        name: 'Kyrgyzstan',
        code: 'KG',
        continent: 'AS',
    },
    {
        name: "Lao People's Democratic Republic",
        code: 'LA',
        continent: 'AS',
    },
    {
        name: 'Latvia',
        code: 'LV',
        continent: 'EU',
    },
    {
        name: 'Lebanon',
        code: 'LB',
        continent: 'AS',
    },
    {
        name: 'Lesotho',
        code: 'LS',
        continent: 'AF',
    },
    {
        name: 'Liberia',
        code: 'LR',
        continent: 'AF',
    },
    {
        name: 'Libyan Arab Jamahiriya',
        code: 'LY',
        continent: 'AF',
    },
    {
        name: 'Liechtenstein',
        code: 'LI',
        continent: 'EU',
    },
    {
        name: 'Lithuania',
        code: 'LT',
        continent: 'EU',
    },
    {
        name: 'Luxembourg',
        code: 'LU',
        continent: 'EU',
    },
    {
        name: 'Macao',
        code: 'MO',
        continent: 'AS',
    },
    {
        name: 'Macedonia, The Former Yugoslav Republic of',
        code: 'MK',
        continent: 'EU',
    },
    {
        name: 'Madagascar',
        code: 'MG',
        continent: 'AF',
    },
    {
        name: 'Malawi',
        code: 'MW',
        continent: 'AF',
    },
    {
        name: 'Malaysia',
        code: 'MY',
        continent: 'AS',
    },
    {
        name: 'Maldives',
        code: 'MV',
        continent: 'AS',
    },
    {
        name: 'Mali',
        code: 'ML',
        continent: 'AF',
    },
    {
        name: 'Malta',
        code: 'MT',
        continent: 'EU',
    },
    {
        name: 'Marshall Islands',
        code: 'MH',
        continent: 'OC',
    },
    {
        name: 'Martinique',
        code: 'MQ',
        continent: 'NA',
    },
    {
        name: 'Mauritania',
        code: 'MR',
        continent: 'AF',
    },
    {
        name: 'Mauritius',
        code: 'MU',
        continent: 'AF',
    },
    {
        name: 'Mayotte',
        code: 'YT',
        continent: 'AF',
    },
    {
        name: 'Mexico',
        code: 'MX',
        continent: 'NA',
    },
    {
        name: 'Micronesia, Federated States of',
        code: 'FM',
        continent: 'OC',
    },
    {
        name: 'Moldova, Republic of',
        code: 'MD',
        continent: 'EU',
    },
    {
        name: 'Monaco',
        code: 'MC',
        continent: 'EU',
    },
    {
        name: 'Mongolia',
        code: 'MN',
        continent: 'AS',
    },
    {
        name: 'Montenegro',
        code: 'ME',
        continent: 'EU',
    },
    {
        name: 'Montserrat',
        code: 'MS',
        continent: 'NA',
    },
    {
        name: 'Morocco',
        code: 'MA',
        continent: 'AF',
    },
    {
        name: 'Mozambique',
        code: 'MZ',
        continent: 'AF',
    },
    {
        name: 'Myanmar',
        code: 'MM',
        continent: 'AS',
    },
    {
        name: 'Namibia',
        code: 'NA',
        continent: 'AF',
    },
    {
        name: 'Nauru',
        code: 'NR',
        continent: 'OC',
    },
    {
        name: 'Nepal',
        code: 'NP',
        continent: 'AS',
    },
    {
        name: 'Netherlands',
        code: 'NL',
        continent: 'EU',
    },
    {
        name: 'New Caledonia',
        code: 'NC',
        continent: 'OC',
    },
    {
        name: 'New Zealand',
        code: 'NZ',
        continent: 'OC',
    },
    {
        name: 'Nicaragua',
        code: 'NI',
        continent: 'NA',
    },
    {
        name: 'Niger',
        code: 'NE',
        continent: 'AF',
    },
    {
        name: 'Nigeria',
        code: 'NG',
        continent: 'AF',
    },
    {
        name: 'Niue',
        code: 'NU',
        continent: 'OC',
    },
    {
        name: 'Norfolk Island',
        code: 'NF',
        continent: 'OC',
    },
    {
        name: 'Northern Mariana Islands',
        code: 'MP',
        continent: 'OC',
    },
    {
        name: 'Norway',
        code: 'NO',
        continent: 'EU',
    },
    {
        name: 'Oman',
        code: 'OM',
        continent: 'AS',
    },
    {
        name: 'Pakistan',
        code: 'PK',
        continent: 'AS',
    },
    {
        name: 'Palau',
        code: 'PW',
        continent: 'OC',
    },
    {
        name: 'Palestinian Territory, Occupied',
        code: 'PS',
        continent: 'AS',
    },
    {
        name: 'Panama',
        code: 'PA',
        continent: 'NA',
    },
    {
        name: 'Papua New Guinea',
        code: 'PG',
        continent: 'OC',
    },
    {
        name: 'Paraguay',
        code: 'PY',
        continent: 'SA',
    },
    {
        name: 'Peru',
        code: 'PE',
        continent: 'SA',
    },
    {
        name: 'Philippines',
        code: 'PH',
        continent: 'AS',
    },
    {
        name: 'Pitcairn',
        code: 'PN',
        continent: 'OC',
    },
    {
        name: 'Poland',
        code: 'PL',
        continent: 'EU',
    },
    {
        name: 'Portugal',
        code: 'PT',
        continent: 'EU',
        priority: true,
    },
    {
        name: 'Puerto Rico',
        code: 'PR',
        continent: 'NA',
    },
    {
        name: 'Qatar',
        code: 'QA',
        continent: 'AS',
    },
    {
        name: 'Reunion',
        code: 'RE',
        continent: 'AF',
    },
    {
        name: 'Romania',
        code: 'RO',
        continent: 'EU',
    },
    {
        name: 'Russian Federation',
        code: 'RU',
        continent: 'EU',
    },
    {
        name: 'RWANDA',
        code: 'RW',
        continent: 'AF',
    },
    {
        name: 'Saint Helena',
        code: 'SH',
        continent: 'AF',
    },
    {
        name: 'Saint Kitts and Nevis',
        code: 'KN',
        continent: 'NA',
    },
    {
        name: 'Saint Lucia',
        code: 'LC',
        continent: 'NA',
    },
    {
        name: 'Saint Pierre and Miquelon',
        code: 'PM',
        continent: 'NA',
    },
    {
        name: 'Saint Vincent and the Grenadines',
        code: 'VC',
        continent: 'NA',
    },
    {
        name: 'Samoa',
        code: 'WS',
        continent: 'OC',
    },
    {
        name: 'San Marino',
        code: 'SM',
        continent: 'EU',
    },
    {
        name: 'Sao Tome and Principe',
        code: 'ST',
        continent: 'AF',
    },
    {
        name: 'Saudi Arabia',
        code: 'SA',
        continent: 'AS',
    },
    {
        name: 'Senegal',
        code: 'SN',
        continent: 'AF',
    },
    {
        name: 'Serbia',
        code: 'RS',
        continent: 'EU',
    },
    {
        name: 'Seychelles',
        code: 'SC',
        continent: 'AF',
    },
    {
        name: 'Sierra Leone',
        code: 'SL',
        continent: 'AF',
    },
    {
        name: 'Singapore',
        code: 'SG',
        continent: 'AS',
    },
    {
        name: 'Slovakia',
        code: 'SK',
        continent: 'EU',
    },
    {
        name: 'Slovenia',
        code: 'SI',
        continent: 'EU',
    },
    {
        name: 'Solomon Islands',
        code: 'SB',
        continent: 'OC',
    },
    {
        name: 'Somalia',
        code: 'SO',
        continent: 'AF',
    },
    {
        name: 'South Africa',
        code: 'ZA',
        continent: 'AF',
    },
    {
        name: 'South Georgia and the South Sandwich Islands',
        code: 'GS',
        continent: 'AN',
    },
    {
        name: 'Spain',
        code: 'ES',
        continent: 'EU',
        priority: true,
    },
    {
        name: 'Sri Lanka',
        code: 'LK',
        continent: 'AS',
    },
    {
        name: 'Sudan',
        code: 'SD',
        continent: 'AF',
    },
    {
        name: 'Suriname',
        code: 'SR',
        continent: 'SA',
    },
    {
        name: 'Svalbard and Jan Mayen',
        code: 'SJ',
        continent: 'EU',
    },
    {
        name: 'Swaziland',
        code: 'SZ',
        continent: 'AF',
    },
    {
        name: 'Sweden',
        code: 'SE',
        continent: 'EU',
    },
    {
        name: 'Switzerland',
        code: 'CH',
        continent: 'EU',
        priority: true,
    },
    {
        name: 'Syrian Arab Republic',
        code: 'SY',
        continent: 'AS',
    },
    {
        name: 'Taiwan, Province of China',
        code: 'TW',
        continent: 'AS',
    },
    {
        name: 'Tajikistan',
        code: 'TJ',
        continent: 'AS',
    },
    {
        name: 'Tanzania, United Republic of',
        code: 'TZ',
        continent: 'AF',
    },
    {
        name: 'Thailand',
        code: 'TH',
        continent: 'AS',
    },
    {
        name: 'Timor-Leste',
        code: 'TL',
        continent: 'OC',
    },
    {
        name: 'Togo',
        code: 'TG',
        continent: 'AF',
    },
    {
        name: 'Tokelau',
        code: 'TK',
        continent: 'OC',
    },
    {
        name: 'Tonga',
        code: 'TO',
        continent: 'OC',
    },
    {
        name: 'Trinidad and Tobago',
        code: 'TT',
        continent: 'NA',
    },
    {
        name: 'Tunisia',
        code: 'TN',
        continent: 'AF',
    },
    {
        name: 'Turkey',
        code: 'TR',
        continent: 'AS',
    },
    {
        name: 'Turkmenistan',
        code: 'TM',
        continent: 'AS',
    },
    {
        name: 'Turks and Caicos Islands',
        code: 'TC',
        continent: 'NA',
    },
    {
        name: 'Tuvalu',
        code: 'TV',
        continent: 'OC',
    },
    {
        name: 'Uganda',
        code: 'UG',
        continent: 'AF',
    },
    {
        name: 'Ukraine',
        code: 'UA',
        continent: 'EU',
    },
    {
        name: 'United Arab Emirates',
        code: 'AE',
        continent: 'AS',
    },
    {
        name: 'United Kingdom',
        code: 'GB',
        continent: 'EU',
        priority: true,
    },
    {
        name: 'United States',
        code: 'US',
        continent: 'NA',
    },
    {
        name: 'United States Minor Outlying Islands',
        code: 'UM',
        continent: 'OC',
    },
    {
        name: 'Uruguay',
        code: 'UY',
        continent: 'SA',
    },
    {
        name: 'Uzbekistan',
        code: 'UZ',
        continent: 'AS',
    },
    {
        name: 'Vanuatu',
        code: 'VU',
        continent: 'OC',
    },
    {
        name: 'Venezuela',
        code: 'VE',
        continent: 'SA',
    },
    {
        name: 'Viet Nam',
        code: 'VN',
        continent: 'AS',
    },
    {
        name: 'Virgin Islands, British',
        code: 'VG',
        continent: 'NA',
    },
    {
        name: 'Virgin Islands, U.S.',
        code: 'VI',
        continent: 'NA',
    },
    {
        name: 'Wallis and Futuna',
        code: 'WF',
        continent: 'OC',
    },
    {
        name: 'Western Sahara',
        code: 'EH',
        continent: 'AF',
    },
    {
        name: 'Yemen',
        code: 'YE',
        continent: 'AS',
    },
    {
        name: 'Zambia',
        code: 'ZM',
        continent: 'AF',
    },
    {
        name: 'Zimbabwe',
        code: 'ZW',
        continent: 'AF',
    },
];

export const continents: IContinent[] = [
    { translation: 'afric', code: 'AF', position: 3 },
    { translation: 'asie', code: 'AS', position: 2 },
    { translation: 'europe', code: 'EU', position: 1 },
    { translation: 'north-america', code: 'NA', position: 4 },
    { translation: 'south-america', code: 'SA', position: 5 },
    { translation: 'oceania', code: 'OC', position: 6 },
    { translation: 'antartica', code: 'AN', position: 7 },
];

// AF : Afrique
// AS : Asie
// EU : Europe
// NA : Amérique du Nord
// SA : Amérique du Sud
// OC : Océanie
// AN : Antarctique

/**
 * Check File Type on FormBuilder
 * @param type
 * @returns
 */
export const requiredFileType = (types: string[], file: File): boolean => {
    if (file) {
        const extensions = file.name.split('.')[1].toLowerCase();
        if (!types.map((e) => e.toLowerCase()).includes(extensions)) {
            return false;
        }
    }
    return true;
};

/**
 * Check File Size on FormBuilder
 * @param size
 * @returns
 */
export const requiredFileSize = (size: number, file: File): any => {
    if (file) {
        const checkSize = file.size;
        if (checkSize > size) {
            return false;
        }
    }
    return true;
};
