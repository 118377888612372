import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable, switchMap, take } from 'rxjs';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { PusherService } from 'app/core/pusher/pusher.service';
import { subscribeToChannel } from 'app/core/global.helpers';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    private _notifications: BehaviorSubject<Notification[]> =
        new BehaviorSubject<Notification[]>([]);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _pusherService: PusherService
    ) {
        this._pusherService.channels$
            .pipe(
                // withLatestFrom(this._notifications),
                subscribeToChannel(
                    'userChannel',
                    'notifications',
                    (data: string) => {
                        const notification: Notification =
                            AuthUtils.fullAesDecode(data);
                        const currentNotifications =
                            this._notifications.getValue();
                        this._notifications.next([
                            ...currentNotifications,
                            notification,
                        ]);
                        // return data;
                    }
                )
            )
            .subscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for notifications
     */
    get notifications$(): Observable<Notification[]> {
        return this._notifications.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all notifications
     */
    getAll(): Observable<Notification[]> {
        return this._httpClient
            .get<string>(
                environment.apiUrl + '/v1/notification/get-notifications'
            )
            .pipe(
                map((notifications) => {
                    const values: Notification[] = JSON.parse(
                        AuthUtils.aesDecode(notifications)
                    );
                    this._notifications.next(values);
                    return values;
                })
            );
    }

    /**
     * Create a notification
     *
     * @param notification
     */
    create(notification: Notification): Observable<Notification> {
        return this.notifications$.pipe(
            take(1),
            // switchMap((notifications) =>
            //     this._httpClient
            //         .post<Notification>('api/common/notifications', {
            //             notification,
            //         })
            //         .pipe(
            map((notifications) => {
                // Update the notifications with the new notification
                this._notifications.next([...notifications, notification]);

                // Return the new notification from observable
                return notification;
            })
            // )
            // )
        );
    }

    /**
     * Update the notification
     *
     * @param id
     * @param notification
     */
    update(id: string, notification: Notification): Observable<Notification> {
        return this.notifications$.pipe(
            take(1),
            switchMap((notifications) =>
                this._httpClient
                    .put<string>(
                        environment.apiUrl +
                            '/v1/notification/toggle-notification',
                        {
                            id,
                            notification,
                        }
                    )
                    .pipe(
                        map((updatedNotification: string) => {
                            const values: Notification = JSON.parse(
                                AuthUtils.aesDecode(updatedNotification)
                            );

                            // Find the index of the updated notification
                            const index = notifications.findIndex(
                                (item) => item.id === id
                            );

                            // Update the notification
                            notifications[index] = values;

                            // Update the notifications
                            this._notifications.next(notifications);

                            // Return the updated notification
                            return values;
                        })
                    )
            )
        );
    }

    /**
     * Delete the notification
     *
     * @param id
     */
    delete(id: string): Observable<boolean> {
        return this.notifications$.pipe(
            take(1),
            switchMap((notifications) =>
                this._httpClient
                    .delete<boolean>(
                        environment.apiUrl +
                            '/v1/notification/delete-notification',
                        {
                            params: { id },
                        }
                    )
                    .pipe(
                        map((isDeleted: boolean) => {
                            // Find the index of the deleted notification
                            const index = notifications.findIndex(
                                (item) => item.id === id
                            );

                            // Delete the notification
                            notifications.splice(index, 1);

                            // Update the notifications
                            this._notifications.next(notifications);

                            // Return the deleted status
                            return isDeleted;
                        })
                    )
            )
        );
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): Observable<boolean> {
        return this.notifications$.pipe(
            take(1),
            switchMap((notifications) =>
                this._httpClient
                    .patch<boolean>(
                        environment.apiUrl +
                            '/v1/notification/read-all-notifications',
                        {}
                    )
                    .pipe(
                        map((isUpdated: boolean) => {
                            // Go through all notifications and set them as read
                            notifications.forEach((notification, index) => {
                                notifications[index].read = true;
                            });

                            // Update the notifications
                            this._notifications.next(notifications);

                            // Return the updated status
                            return isUpdated;
                        })
                    )
            )
        );
    }

    /**
     * Delete All the notification
     *
     */
    deleteAll(): Observable<boolean> {
        return this.notifications$.pipe(
            take(1),
            switchMap((notifications) =>
                this._httpClient
                    .delete<boolean>(
                        environment.apiUrl +
                            '/v1/notification/delete-notifications'
                    )
                    .pipe(
                        map((isDeleted: boolean) => {
                            // Update the notifications
                            this._notifications.next([]);

                            // Return the deleted status
                            return isDeleted;
                        })
                    )
            )
        );
    }
}
