import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, take, tap } from 'rxjs';
import { ICodeChecking } from 'app/core/global.types';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PhoneConfirmationService {
    private _confirmation: BehaviorSubject<ICodeChecking> = new BehaviorSubject(
        null
    );

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors link to Mindee side
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for Mindee callback
     */
    get confirmation$(): Observable<ICodeChecking> {
        return this._confirmation.asObservable();
    }
    /**
     * Confirms the provided code with the server and returns the server's response.
     *
     * @param code - The code to be confirmed.
     * @returns An observable that emits the server's response, which is of type `ICodeChecking`.
     */

    confirmCode(code: string): Observable<ICodeChecking> {
        return this._httpClient
            .post<ICodeChecking>(
                environment.apiUrl + '/v1/oauth/phone-verification',
                {
                    code: code,
                }
            )
            .pipe(take(1));
    }

    /**
     * Recalls the verification status from the server.
     *
     * @returns An observable that emits a boolean indicating whether the verification was successful.
     */
    recallVerification(): Observable<boolean> {
        return this._httpClient.get<boolean>(
            environment.apiUrl + '/v1/oauth/recall-verification'
        );
    }
}
