import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { wholeSaleRoutes } from './wholesale.routing';
import { pharmaRoutes } from './pharma.routing';
import { InitialDataResolver } from './app.resolver';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    { path: '', pathMatch: 'full', redirectTo: 'home' },
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'changelog' },
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.module'
                    ).then((m) => m.AuthConfirmationRequiredModule),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.module'
                    ).then((m) => m.AuthForgotPasswordModule),
            },
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.module'
                    ).then((m) => m.AuthResetPasswordModule),
            },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.module').then(
                        (m) => m.AuthSignInModule
                    ),
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.module').then(
                        (m) => m.AuthSignUpModule
                    ),
            },
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.module'
                    ).then((m) => m.AuthConfirmationRequiredModule),
            },
            {
                path: 'check-confirmation',
                loadChildren: () =>
                    import(
                        'app/modules/auth/check-confirmation/check-confirmation.module'
                    ).then((m) => m.AuthCheckConfirmationModule),
            },
        ],
    },
    {
        path: '',
        data: {
            secure: true,
            layout: 'empty',
        },
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        children: [
            {
                path: 'sign-out',
                data: {
                    name: 'Logout Module',
                    description: 'Disconnect page for the user connected',
                },
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.module').then(
                        (m) => m.AuthSignOutModule
                    ),
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.module'
                    ).then((m) => m.AuthUnlockSessionModule),
            },
            // {
            //     path: 'phone-verification',
            //     loadChildren: () =>
            //         import(
            //             'app/modules/auth/phone-confirmation/phone-confirmation.module'
            //         ).then((m) => m.PhoneConfirmationModule),
            // },
        ],
    },
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'landing',
        },
        children: [
            {
                data: {
                    title: 'Expepharma: European market place',
                    description:
                        'Connect with European pharmaceutical wholesalers and distributors. Join our platform to optimize your supply chain and grow your business',
                    ogUrl: 'https://expepharma.com/home',
                },
                path: 'home',
                loadChildren: () =>
                    import('app/modules/landing/home/home.module').then(
                        (m) => m.LandingHomeModule
                    ),
            },
        ],
    },
    ...wholeSaleRoutes,
    ...pharmaRoutes,
    {
        path: 'error',
        children: [
            {
                path: '404',
                loadChildren: () =>
                    import('app/modules/error/error-404/error-404.module').then(
                        (m) => m.Error404Module
                    ),
            },
            {
                path: '500',
                loadChildren: () =>
                    import('app/modules/error/error-500/error-500.module').then(
                        (m) => m.Error500Module
                    ),
            },
            {
                path: 'unauthorized',
                loadChildren: () =>
                    import(
                        'app/modules/error/error-access/error-access.module'
                    ).then((m) => m.ErrorAccessModule),
            },
        ],
    },
    {
        path: '404-not-found',
        pathMatch: 'full',
        loadChildren: () =>
            import('app/modules/error/error-404/error-404.module').then(
                (m) => m.Error404Module
            ),
    },
    { path: '**', redirectTo: '404-not-found' },
];
