import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import {
    combineLatest,
    EMPTY,
    forkJoin,
    map,
    Observable,
    of,
    switchMap,
    take,
    zip,
} from 'rxjs';
import { MessagesService } from 'app/layout/common/messages/messages.service';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { QuickChatService } from 'app/layout/common/quick-chat/quick-chat.service';
import { ShortcutsService } from 'app/layout/common/shortcuts/shortcuts.service';
import { UserService } from 'app/core/user/user.service';
import { AuthService } from 'app/core/auth/auth.service';
import { User } from 'app/core/user/user.types';

@Injectable({
    providedIn: 'root',
})
export class HomeResolver implements Resolve<any> {
    /**
     * Constructor
     */
    constructor(
        private _userService: UserService,
        private _authService: AuthService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Use this resolver to resolve initial mock-api for the application
     *
     * @param route
     * @param state
     */
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<User> {
        return this._authService.check().pipe(
            switchMap((check) => {
                if (check === true) {
                    return this._authService.checkPermission('home', true).pipe(
                        switchMap((permission) => {
                            if (permission === true) {
                                return this._userService.get().pipe(
                                    map((user) => {
                                        if (user?.first_name) {
                                            return user;
                                        }
                                        return null;
                                    })
                                );
                            }
                            return of(null);
                        })
                    );
                }
                return of(null);
            })
        );
    }
}
