// popover.service.ts
import { Injectable, Injector } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ExpepharmaPopoverComponent } from './popover.component';

@Injectable({
    providedIn: 'root',
})
export class ExpepharmaPopoverService {
    private overlayRef: OverlayRef;

    constructor(private overlay: Overlay, private injector: Injector) {}

    open(
        { origin, content },
        type?: 'warning' | 'info' | 'success' | 'error' | 'classic'
    ) {
        if (this.overlayRef) {
            return;
        }
        const positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo(origin)
            .withPositions([
                {
                    originX: 'center',
                    originY: 'bottom',
                    overlayX: 'center',
                    overlayY: 'top',
                    offsetY: 8,
                },
            ]);

        const overlayConfig = new OverlayConfig({
            hasBackdrop: false,
            backdropClass: 'popover-backdrop',
            positionStrategy,
        });

        this.overlayRef = this.overlay.create(overlayConfig);

        const popoverPortal = new ComponentPortal(
            ExpepharmaPopoverComponent,
            null,
            this.injector
        );
        const popoverRef = this.overlayRef.attach(popoverPortal);
        popoverRef.instance.content = content;
        if (type) {
            popoverRef.instance.type = type;
        }

        this.overlayRef.backdropClick().subscribe(() => this.close());
    }

    close() {
        if (this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef = null;
        }
    }

    isOpen(): boolean {
        return !!this.overlayRef;
    }
}
