import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ExpepharmaFooterComponent } from 'app/layout/layouts/footer/footer.component';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';

@NgModule({
    providers: [{ provide: TRANSLOCO_SCOPE, useValue: ['landing'] }],
    declarations: [ExpepharmaFooterComponent],
    imports: [RouterModule, TranslocoModule, MatDividerModule, CommonModule],
    exports: [ExpepharmaFooterComponent],
})
export class ExpepharmaFooterModule {}
