import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { LocalizedDatePipe } from 'app/core/global.helpers';
import * as moment from 'moment';
import { TranslocoService } from '@ngneat/transloco';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';
import { BasketService } from '../basket.service';
import { IBasket } from '../basket.types';

@Component({
    templateUrl: './modal-warning.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalizedDatePipe],
})
export class ModalWarningComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    isDisabled: boolean = false;
    /**
     * Constructor
     */
    constructor(
        private _basketService: BasketService,
        public matDialogRef: MatDialogRef<ModalWarningComponent>,
        @Inject(MAT_DIALOG_DATA) public item: IBasket,
        public _translocoService: TranslocoService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._basketService
            .updateModalStatus(this.item.basket.id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Increases the deadline for the basket item by calling the basket service.
     *
     * Updates the deadline by passing the offer id, product id and offer hash to the
     * basket service updateDeadlineFromBasket method.
     */
    increaseDeadline(message: string) {
        this.isDisabled = true;
        this._basketService
            .updateDeadlineFromBasket(
                this.item.offer.id,
                this.item.offer.product.id,
                this.item.offer.hash,
                message
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((e) => {
                this.isDisabled = false;
                this.closeModal();
            });
    }

    /**
     * Closes the modal dialog.
     */
    closeModal(): void {
        this.matDialogRef.close();
    }

    /**
     * Removes an item from the basket.
     *
     * @param offer_id - The offer ID of the item to remove.
     * @param product_id - The product ID of the item to remove.
     * @param hash - The unique hash of the item to remove.
     */
    removeItem(offer_id: number, product_id: number, hash: string): void {
        this.isDisabled = true;
        this._basketService
            .removeFromBasket(offer_id, product_id, hash)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((e) => {
                this.isDisabled = false;
                this.closeModal();
            });
    }
}
