import { Component, Inject, OnInit } from '@angular/core';
import { expepharmaAnimations } from '@expepharma/animations';
import { Subject, takeUntil } from 'rxjs';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';
import { TTypeAccount, User } from 'app/core/user/user.types';
import { PhoneConfirmationComponent } from '../phone-confirmation/phone-confirmation.component';
import { UserService } from 'app/core/user/user.service';
import { ValidationAccountComponent } from '../validation-account/validation-account.component';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    templateUrl: './choice-type-validation.component.html',
    styleUrls: ['./choice-type-validation.component.scss'],
    animations: expepharmaAnimations,
})
export class ChoiceTypeValidationComponent implements OnInit {
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        public _matDialogRef: MatDialogRef<ChoiceTypeValidationComponent>,
        private _matDialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _userService: UserService,
        private _sanitizer: DomSanitizer,
        private _translocoService: TranslocoService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {}

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Opens a modal dialog to confirm the user's phone number.
     *
     * @param user - The user object containing the user's information.
     * @returns Void
     */
    goToPhoneValidation() {
        const modal = this._matDialog.open(PhoneConfirmationComponent, {
            data: {
                type: this.data.type,
                suscription: this.data.suscription,
                title: this.titleModal(this.data.type),
            },
            maxWidth: 500,
            width: '500px',
            disableClose: true,
        });

        modal.afterOpened().subscribe(() => {
            this._matDialogRef.close();
        });

        modal.afterClosed().subscribe(() => {
            this._userService
                .updatePhoneActivation(true)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe();
        });
    }

    gotToManualValidation() {
        const modal = this._matDialog.open(ValidationAccountComponent, {
            data: {
                type: this.data.type,
                suscription: this.data.suscription,
                title: this.titleModal(this.data.type),
            },
            maxWidth: 500,
            width: '500px',
            disableClose: true,
        });

        modal.afterOpened().subscribe(() => {
            this._matDialogRef.close();
        });
    }

    /**
     * Determines the title of the modal based on the user's account type.
     * @param type - The type of the user's account.
     * @returns The title of the modal.
     */
    titleModal(type: TTypeAccount): string {
        switch (type) {
            case 'wholesaler_pharma':
                return 'selected-type-wholesaler-pharma';
            case 'wholesaler_nopharma':
                return 'selected-type-wholesaler-nopharma';
            default:
                return 'selected-type-pharma';
        }
    }

    get explanationChoiceTypeValidation() {
        return this._sanitizer.bypassSecurityTrustHtml(
            this._translocoService.translate(
                'explanation-choice-type-validation'
            )
        );
    }

    closeModal() {
        this._matDialogRef.close();
    }
}
