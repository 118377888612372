// popover.module.ts
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { ExpepharmaPopoverComponent } from './popover.component';
import { ExpepharmaPopoverService } from './popover.service';

@NgModule({
    declarations: [ExpepharmaPopoverComponent],
    imports: [CommonModule, OverlayModule],
    exports: [ExpepharmaPopoverComponent],
    providers: [ExpepharmaPopoverService],
})
export class ExpepharmaPopoverModule {}
