import { Injectable } from '@angular/core';
import { ExpepharmaApiService } from '@expepharma/lib/api';
import { user as userData } from 'app/api/common/user/data';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthApi {
    private _user: any = userData;

    /**
     * Constructor
     */
    constructor(private _expepharmaApiService: ExpepharmaApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Forgot password - POST
        // -----------------------------------------------------------------------------------------------------
        this._expepharmaApiService.onPost(
            environment.apiUrl + '/v1/auth/forgot-password'
        );
        // -----------------------------------------------------------------------------------------------------
        // @ Reset password - POST
        // -----------------------------------------------------------------------------------------------------
        this._expepharmaApiService.onPost(
            environment.apiUrl + '/v1/auth/reset-password'
        );
        // -----------------------------------------------------------------------------------------------------
        // @ Sign in - POST
        // -----------------------------------------------------------------------------------------------------
        this._expepharmaApiService.onPost(
            environment.apiUrl + '/v1/oauth/sign-in'
        );
        // -----------------------------------------------------------------------------------------------------
        // @ Sign in using the access token - POST
        // -----------------------------------------------------------------------------------------------------
        this._expepharmaApiService.onPost(
            environment.apiUrl + '/v1/oauth/sign-in-with-token'
        );
        // -----------------------------------------------------------------------------------------------------
        // @ Sign up - POST
        // -----------------------------------------------------------------------------------------------------
        this._expepharmaApiService.onPost(
            environment.apiUrl + '/v1/auth/sign-up'
        );
        // -----------------------------------------------------------------------------------------------------
        // @ Unlock session - POST
        // -----------------------------------------------------------------------------------------------------
        this._expepharmaApiService.onPost(
            environment.apiUrl + '/v1/auth/unlock-session'
        );
        // -----------------------------------------------------------------------------------------------------
        // @ Logout session - GET
        // -----------------------------------------------------------------------------------------------------
        this._expepharmaApiService.onGet(
            environment.apiUrl + '/v1/oauth/logout'
        );
        // -----------------------------------------------------------------------------------------------------
        // @ Refresh Token session - POST
        // -----------------------------------------------------------------------------------------------------
        this._expepharmaApiService.onPost(
            environment.apiUrl + 'oauth/refresh-token'
        );
        // -----------------------------------------------------------------------------------------------------
        // @ Phone Verification - POST
        // -----------------------------------------------------------------------------------------------------
        this._expepharmaApiService.onPost(
            environment.apiUrl + '/v1/oauth/phone-verification'
        );
    }
}
