import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { TTypeAccount } from 'app/core/user/user.types';
import { Subject } from 'rxjs';

@Component({
    selector: 'switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SwitchComponent implements OnInit, OnDestroy {
    @ViewChild('listItem1') listItem1: ElementRef;
    @ViewChild('listItem2') listItem2: ElementRef;
    isEnabledForSwitch: boolean = false;
    switchPosition: 'pharma' | 'wholesale';
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private renderer: Renderer2,
        private _userService: UserService,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        this._userService.user$.subscribe((user) => {
            this.isEnabledForSwitch = this.isValidType(user.type);
            this.switchPosition =
                'pharma' ===
                AuthUtils.aesDecode(AuthUtils.checkUrl(window.location.href))
                    ? 'pharma'
                    : 'wholesale';
            this._changeDetectorRef.markForCheck();
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {}

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    ngAfterViewInit() {}

    isValidType(type: TTypeAccount) {
        return (
            type === 'owner' ||
            type === 'wholesaler_pharma' ||
            type === 'wholesaler_nopharma'
        );
    }

    onHover() {
        this.renderer.addClass(
            this.listItem1.nativeElement,
            'content__container__list__item--hidden'
        );
        this.renderer.removeClass(
            this.listItem1.nativeElement,
            'content__container__list__item--visible'
        );
        this.renderer.removeClass(
            this.listItem2.nativeElement,
            'content__container__list__item--hidden'
        );
        this.renderer.addClass(
            this.listItem2.nativeElement,
            'content__container__list__item--visible'
        );
    }

    onLeave() {
        this.renderer.removeClass(
            this.listItem1.nativeElement,
            'content__container__list__item--hidden'
        );
        this.renderer.addClass(
            this.listItem1.nativeElement,
            'content__container__list__item--visible'
        );
        this.renderer.removeClass(
            this.listItem2.nativeElement,
            'content__container__list__item--visible'
        );

        this.renderer.addClass(
            this.listItem2.nativeElement,
            'content__container__list__item--hidden'
        );
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
}
