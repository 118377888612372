<!-- Flippable card -->
<ng-container *ngIf="flippable">

    <!-- Front -->
    <div class="expepharma-card-front">
        <ng-content select="[expepharmaCardFront]"></ng-content>
    </div>

    <!-- Back -->
    <div class="expepharma-card-back">
        <ng-content select="[expepharmaCardBack]"></ng-content>
    </div>

</ng-container>

<!-- Normal card -->
<ng-container *ngIf="!flippable">

    <!-- Content -->
    <ng-content></ng-content>

    <!-- Expansion -->
    <div class="expepharma-card-expansion" *ngIf="expanded" [@expandCollapse]>
        <ng-content select="[expepharmaCardExpansion]"></ng-content>
    </div>

</ng-container>
