import { Injectable } from '@angular/core';
import { ExpepharmaAlertService } from '@expepharma/components/alert/alert.service';
import {
    BehaviorSubject,
    delay,
    Observable,
    of,
    Subject,
    switchMap,
    take,
    tap,
} from 'rxjs';
import { IAlert } from './alert.type';
import { ExpepharmaUtilsService } from '@expepharma/services/utils/utils.service';
import { Howl, Howler } from 'howler';

@Injectable({
    providedIn: 'root',
})
export class AlertService extends ExpepharmaAlertService {
    private _alert: BehaviorSubject<IAlert> = new BehaviorSubject(null);
    private defaultValue: IAlert = {
        appearance: 'soft',
        dismissed: false,
        dismissible: false,
        name: this._expepharmaUtilsService.randomId(),
        type: 'primary',
        position: 'top-center',
        message: '',
        clearTime: 5000,
    };

    /**
     * Constructor
     */
    constructor(private _expepharmaUtilsService: ExpepharmaUtilsService) {
        super();
    }

    /**
     * Getter for alerts
     */
    get alert$(): Observable<IAlert> {
        return this._alert.asObservable();
    }

    newAlert(newAlert: IAlert): Observable<IAlert> {
        newAlert.appearance =
            newAlert.appearance || this.defaultValue.appearance;
        newAlert.dismissed = newAlert.dismissed || this.defaultValue.dismissed;
        newAlert.dismissible =
            newAlert.dismissible || this.defaultValue.dismissible;
        newAlert.showIcon = newAlert.showIcon || this.defaultValue.showIcon;
        newAlert.type = newAlert.type || this.defaultValue.type;
        newAlert.position = newAlert.position || this.defaultValue.position;
        newAlert.clearTime = newAlert.clearTime || this.defaultValue.clearTime;

        new Howl({
            src: ['/assets/sounds/notif.mp3'],
            autoplay: true,
            html5: true,
            volume: 0.2,
            // onend: function () {
            // },
        });

        return this.alert$.pipe(
            take(1),
            tap((alert) => {
                this._alert.next(newAlert);
                this.clearTime(newAlert).subscribe();
                return newAlert;
            })
        );
    }

    liveAlert(newAlert: IAlert): void {
        const alertWithDefaults: IAlert = {
            ...this.defaultValue,
            ...newAlert,
            name: newAlert.name || this._expepharmaUtilsService.randomId(),
            dismissed: false,
        };

        new Howl({
            src: ['/assets/sounds/notif.mp3'],
            autoplay: true,
            html5: true,
            volume: 0.2,
        });

        this._alert.next(alertWithDefaults);
        this.clearTime(alertWithDefaults).subscribe();
    }

    clearTime(alert: IAlert): Observable<any> {
        return of(null).pipe(
            delay(alert.clearTime),
            tap(() => {
                alert.dismissed = true;
                this._alert.next({ ...alert, dismissed: true });
            }),
            delay(500),
            tap(() => {
                if (this._alert.getValue()?.name === alert.name) {
                    this._alert.next(null);
                }
            })
        );
    }
}
