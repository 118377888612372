import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ExpepharmaLoadingBarModule } from '@expepharma/components/loading-bar';
import { SharedModule } from 'app/shared/shared.module';
import { LandingLayoutComponent } from 'app/layout/layouts/landing/landing.component';

@NgModule({
    declarations: [LandingLayoutComponent],
    imports: [RouterModule, ExpepharmaLoadingBarModule, SharedModule],
    exports: [LandingLayoutComponent],
})
export class LandingLayoutModule {}
