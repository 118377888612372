import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { take } from 'rxjs';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import {
    ExpepharmaNavigationItem,
    ExpepharmaNavigationService,
    ExpepharmaVerticalNavigationComponent,
} from '@expepharma/components/navigation';
import { DateAdapter } from '@angular/material/core';

@Component({
    selector: 'languages',
    templateUrl: './languages.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'languages',
})
export class LanguagesComponent implements OnInit, OnDestroy {
    availableLangs: AvailableLangs;
    activeLang: string;
    flagCodes: any;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _expepharmaNavigationService: ExpepharmaNavigationService,
        private _translocoService: TranslocoService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Get the available languages from transloco
        this.availableLangs = this._translocoService.getAvailableLangs();

        // Subscribe to language changes
        this._translocoService.langChanges$.subscribe((activeLang) => {
            // Get the active lang
            this.activeLang = activeLang;
            // Update the navigation
            setTimeout(() => {
                this._updateNavigation(activeLang);
            });
        });

        // Set the country iso codes for languages for flags
        this.flagCodes = {
            en: 'gb-ukm',
            fr: 'fr',
            de: 'de',
            ar: 'ar',
            it: 'it',
        };
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the active lang
     *
     * @param lang
     */
    setActiveLang(lang: string): void {
        // Set the active lang
        this._translocoService.setActiveLang(lang);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the navigation
     *
     * @param lang
     * @private
     */
    private _updateNavigation(lang: string): void {
        // For the demonstration purposes, we will only update the Dashboard names
        // from the navigation but you can do a full swap and change the entire
        // navigation data.
        //
        // You can import the data from a file or request it from your backend,
        // it's up to you.

        // Get the component -> navigation data -> item
        const navComponent =
            this._expepharmaNavigationService.getComponent<ExpepharmaVerticalNavigationComponent>(
                'mainNavigation'
            );
        // Return if the navigation component does not exist
        if (!navComponent) {
            return null;
        } else {
            this._translocoService
                .load(this.activeLang)
                .toPromise()
                .then((e) => {
                    navComponent.navigation = this.updateTranslation(
                        navComponent.navigation,
                        []
                    );
                    navComponent.refresh();
                });
        }
    }

    updateTranslation(
        navs: ExpepharmaNavigationItem[],
        arr: ExpepharmaNavigationItem[]
    ): ExpepharmaNavigationItem[] {
        for (let nav of navs) {
            if (nav.titleKey) {
                nav.title = this._translocoService.translate(
                    'navigation.' + nav.titleKey
                );
            }

            if (nav.subtitleKey) {
                nav.subtitle = this._translocoService.translate(
                    'navigation.' + nav.subtitleKey
                );
            }

            if (nav.children) {
                nav.children = this.updateTranslation(nav.children, []);
            }
            arr.push(nav);
        }
        return arr;
    }
}
