import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from 'app/shared/shared.module';
import { DisplayCustomerComponent } from './display-customer.component';

@NgModule({
    declarations: [DisplayCustomerComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatTooltipModule,
        TranslocoModule,
        SharedModule,
    ],
    exports: [DisplayCustomerComponent],
})
export class ExpepharmaDisplayCustomerModule {}
