import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ExpepharmaLoadingBarComponent } from '@expepharma/components/loading-bar/loading-bar.component';

@NgModule({
    declarations: [ExpepharmaLoadingBarComponent],
    imports: [CommonModule, MatProgressBarModule],
    exports: [ExpepharmaLoadingBarComponent],
})
export class ExpepharmaLoadingBarModule {}
