import { ModuleWithProviders, NgModule } from '@angular/core';
import { ExpepharmaConfigService } from '@expepharma/services/config/config.service';
import { EXPEPHARMA_APP_CONFIG } from '@expepharma/services/config/config.constants';

@NgModule()
export class ExpepharmaConfigModule {
    /**
     * Constructor
     */
    constructor(private _expepharmaConfigService: ExpepharmaConfigService) {}

    /**
     * forRoot method for setting user configuration
     *
     * @param config
     */
    static forRoot(config: any): ModuleWithProviders<ExpepharmaConfigModule> {
        return {
            ngModule: ExpepharmaConfigModule,
            providers: [
                {
                    provide: EXPEPHARMA_APP_CONFIG,
                    useValue: config,
                },
            ],
        };
    }
}
