import {
    Component,
    ElementRef,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { expepharmaAnimations } from '@expepharma/animations';
import { ExpepharmaAlertType } from '@expepharma/components/alert';
import { AuthService } from 'app/core/auth/auth.service';
import { PhoneConfirmationService } from './phone-confirmation.service';
import { Subject } from 'rxjs';
import { AlertService } from 'app/core/alerts/alert.service';
import { TranslocoService } from '@ngneat/transloco';
import { UserService } from 'app/core/user/user.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-phone-confirmation',
    templateUrl: './phone-confirmation.component.html',
    styleUrls: ['./phone-confirmation.component.scss'],
    animations: expepharmaAnimations,
})
export class PhoneConfirmationComponent implements OnInit {
    @ViewChild('phoneverificateNgForm') forgotPasswordNgForm: NgForm;
    @ViewChildren('input') inputs: QueryList<ElementRef>;
    fields = Array(6).fill(null);
    isValidated: boolean = false;
    alert: { type: ExpepharmaAlertType; message: string } = {
        type: 'success',
        message: '',
    };
    phoneverificateForm: FormGroup;
    showAlert: boolean = false;
    buttonSpinner: boolean = false;
    alreadyAsk: boolean = false;
    countdown: number = 60;
    intervalId: any;
    buttonDisabled: boolean = false;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        public _matDialogRef: MatDialogRef<PhoneConfirmationComponent>,
        private _phoneVerificationservice: PhoneConfirmationService,
        private _alertService: AlertService,
        private _transloco: TranslocoService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Create the form
        this.phoneverificateForm = new FormGroup({});
        for (let i = 0; i < 6; i++) {
            this.phoneverificateForm.addControl(
                `digit${i}`,
                new FormControl('', Validators.required)
            );
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    onKeyUp(event: KeyboardEvent, index: number): void {
        const input = event.target as HTMLInputElement;
        if (input.value.length >= 1 && index < 5) {
            const nextInputField =
                this.inputs.toArray()[index + 1].nativeElement;
            nextInputField.focus();
        }
    }

    onKeyDown(event: KeyboardEvent, index: number): void {
        const input = event.target as HTMLInputElement;
        if (event.key === 'Backspace' && input.value === '' && index > 0) {
            const previousInputField =
                this.inputs.toArray()[index - 1].nativeElement;
            previousInputField.focus();
        }
    }

    /**
     * Send the reset link
     */
    sendCodeVerification(): void {
        if (this.phoneverificateForm.invalid) {
            return;
        }
        this.phoneverificateForm.disable();

        const code =
            this.phoneverificateForm.get('digit0').value +
            this.phoneverificateForm.get('digit1').value +
            this.phoneverificateForm.get('digit2').value +
            this.phoneverificateForm.get('digit3').value +
            this.phoneverificateForm.get('digit4').value +
            this.phoneverificateForm.get('digit5').value;
        this.showAlert = false;
        this._phoneVerificationservice.confirmCode(code).subscribe({
            next: () => {
                this.isValidated = true;
            },
            error: (err) => {
                this.phoneverificateForm.enable();
                this._alertService.liveAlert({
                    appearance: 'soft',
                    dismissed: false,
                    showIcon: true,
                    type: 'error',
                    position: 'top-center',
                    message: this._transloco.translate(
                        'invalid-phone-validation'
                    ),
                    clearTime: 5000,
                });
            },
        });
    }

    recallVerification() {
        this.alreadyAsk = true;
        this.buttonSpinner = true;
        this.buttonDisabled = true;
        this.intervalId = setInterval(() => {
            this.countdown--;
            if (this.countdown === 0) {
                clearInterval(this.intervalId);
                this.buttonDisabled = false;
                this.countdown = 60;
            }
        }, 1000);

        this._phoneVerificationservice.recallVerification().subscribe({
            next: () => {
                this.buttonSpinner = false;
            },
            error: (err) => {},
        });
    }

    closeModal() {
        this._matDialogRef.close();
        window.location.reload();
    }
}
