// -----------------------------------------------------------------------------------------------------
// @ AUTH UTILITIES
//
// Methods are derivations of the Auth0 Angular-JWT helper service methods
// https://github.com/auth0/angular2-jwt
// -----------------------------------------------------------------------------------------------------

import CryptoJS from 'crypto-js';
import { IApiMethods, IPermission, IApiOptions } from './auth.types';
import { environment } from 'environments/environment';
import { HttpHeaders } from '@angular/common/http';

export class AuthUtils {
    /**
     * Constructor
     */
    constructor() {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Is token expired?
     *
     * @param token
     * @param offsetSeconds
     */
    static isTokenExpired(token: string, offsetSeconds?: number): boolean {
        // Return if there is no token
        if (!token || token === '') {
            return true;
        }

        // Get the expiration date
        const date = this._getTokenExpirationDate(token);

        offsetSeconds = offsetSeconds || 0;

        if (date === null) {
            return true;
        }

        // Check if the token is expired
        return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Base64 decoder
     * Credits: https://github.com/atk
     *
     * @param str
     * @private
     */
    private static _b64decode(str: string): string {
        const chars =
            'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
        let output = '';

        str = String(str).replace(/=+$/, '');

        if (str.length % 4 === 1) {
            throw new Error(
                "'atob' failed: The string to be decoded is not correctly encoded."
            );
        }

        /* eslint-disable */
        for (
            // initialize result and counters
            let bc = 0, bs: any, buffer: any, idx = 0;
            // get next character
            (buffer = str.charAt(idx++));
            // character found in table? initialize bit storage and add its ascii value;
            ~buffer &&
            ((bs = bc % 4 ? bs * 64 + buffer : buffer),
            // and if not first of each 4 characters,
            // convert the first 8 bits to one ascii character
            bc++ % 4)
                ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
                : 0
        ) {
            // try to find character in table (0-63, not found => -1)
            buffer = chars.indexOf(buffer);
        }
        /* eslint-enable */

        return output;
    }

    /**
     * Base64 unicode decoder
     *
     * @param str
     * @private
     */
    private static _b64DecodeUnicode(str: any): string {
        return decodeURIComponent(
            Array.prototype.map
                .call(
                    this._b64decode(str),
                    (c: any) =>
                        '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                )
                .join('')
        );
    }

    /**
     * URL Base 64 decoder
     *
     * @param str
     * @private
     */
    private static _urlBase64Decode(str: string): string {
        let output = str.replace(/-/g, '+').replace(/_/g, '/');
        switch (output.length % 4) {
            case 0: {
                break;
            }
            case 2: {
                output += '==';
                break;
            }
            case 3: {
                output += '=';
                break;
            }
            default: {
                throw Error('Illegal base64url string!');
            }
        }
        return this._b64DecodeUnicode(output);
    }

    /**
     * Decode token
     *
     * @param token
     * @private
     */
    private static _decodeToken(token: string): any {
        // Return if there is no token
        if (!token) {
            return null;
        }

        // Split the token
        const parts = token.split('.');

        if (parts.length !== 3) {
            throw new Error(
                "The inspected token doesn't appear to be a JWT. Check to make sure it has three parts and see https://jwt.io for more."
            );
        }

        // Decode the token using the Base64 decoder
        const decoded = this._urlBase64Decode(parts[1]);

        if (!decoded) {
            throw new Error('Cannot decode the token.');
        }

        return JSON.parse(decoded);
    }

    /**
     * Get token expiration date
     *
     * @param token
     * @private
     */
    private static _getTokenExpirationDate(token: string): Date | null {
        // Get the decoded token
        const decodedToken = this._decodeToken(token);

        // Return if the decodedToken doesn't have an 'exp' field
        if (!decodedToken.hasOwnProperty('exp')) {
            return null;
        }

        // Convert the expiration date
        const date = new Date(0);
        date.setUTCSeconds(decodedToken.exp);

        return date;
    }

    /**
     * Check the permission per verb
     *
     * @param method
     * @param permissions
     */
    static permissionVerbs(
        method: IApiMethods,
        permissions: IPermission,
        option?: IApiOptions
    ): boolean {
        switch (method) {
            case 'get':
                switch (option) {
                    case 'download':
                        return permissions.download;
                    case 'upload':
                        return permissions.upload;
                    case 'secure':
                        return permissions.secure;
                    default:
                        return permissions?.access ?? false;
                }
            case 'post':
                return permissions.create;
            case 'put':
                return permissions.update;
            case 'patch':
                return permissions.update;
            case 'delete':
                return permissions.delete;
            default:
                break;
        }
    }

    /**
     * Check if path send is include on list of path enable without permissions verification
     *
     * @param path
     */
    static excludingCheckUrl(path): boolean {
        const listPathAlow = [
            'assets',
            'oauth/permission',
            'ask-demo',
            'sign-in',
            'sign-out',
            'signed-in-redirect',
            'reset-password',
            'forgot-password',
            'confirmation-required',
            'sign-up',
            'unlock-session',
            'home',
            'mindee/check-wdl',
            'mindee/check-gdp',
            'check-form/license-wdl',
            'check-form/license-gdp',
            'check-form/email-user',
            'check-form/pseudo-user',
            'params/pharma-countries',
            'check-form/code-validation',
            'phone-verification',
            'pharma/filters',
            'pharma-countries',
            'hokodo/find-company',
            'version.json',
        ];

        return listPathAlow.filter((e) => path.includes(e)).length > 0
            ? true
            : false;
    }

    /**
     * Convert string to encrypt data
     *
     * @param value
     */
    static aesEncode(value: string): string {
        const encryptedData = CryptoJS.AES.encrypt(
            value,
            environment.aesKey
        ).toString();
        const hmac = CryptoJS.HmacSHA256(value, environment.aesKey).toString();
        const payload = encryptedData + ':' + hmac;
        return payload;
    }

    /**
     * Convert encrypt data to string
     *
     * @param value
     */
    static aesDecode(payload: string): string {
        try {
            if (typeof payload !== 'string' || !payload.includes(':')) {
                return null;
            }
            const parts = payload.split(':');
            if (parts.length !== 2) {
                return null;
            }

            const [encryptedData, receivedHmac] = parts;
            const decrypted = CryptoJS.AES.decrypt(
                encryptedData,
                environment.aesKey
            );
            const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

            if (!decryptedData) {
                return null;
            }

            const calculatedHmac = CryptoJS.HmacSHA256(
                decryptedData,
                environment.aesKey
            ).toString();

            return calculatedHmac === receivedHmac ? decryptedData : null;
        } catch (error) {
            return null;
        }
    }

    static fullAesEncode(value: any): string {
        return AuthUtils.aesEncode(JSON.stringify(value));
    }

    static fullAesDecode(value: string): any {
        const hashDecrypt = AuthUtils.aesDecode(value);
        return hashDecrypt === null ? null : JSON.parse(hashDecrypt);
    }

    static addOptionOtherGet(type: 'download' | 'upload' | 'secure') {
        return {
            headers: new HttpHeaders({
                option: type,
            }),
        };
    }

    static checkUrl(currentUrl) {
        const pathRegex =
            /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/;
        const match = currentUrl.replace(pathRegex, '');

        let containsPharma = match.includes('pharma');
        let containsWholesale = match.includes('wholesale');

        if (containsPharma) {
            return AuthUtils.aesEncode('pharma');
        } else if (containsWholesale) {
            return AuthUtils.aesEncode('wholesale');
        } else {
            return AuthUtils.aesEncode(null);
        }
    }
}
