<router-outlet></router-outlet>

<div class="alert-area" *ngIf="alert$ | async as alert" [ngClass]="alert.position">
    <expepharma-alert [appearance]="alert.appearance" [dismissible]="true" [dismissed]="alert.dismissed"
        [name]="'alert.name'" [type]="alert.type">
        {{alert.message}}
    </expepharma-alert>
</div>


<!-- modal.component.html -->
<div class="modal" *ngIf="showModal" [@modalState]="showModal ? 'visible' : 'hidden'">
    <div class="modal-content">
        <h1 class="font-semibold text-xl">{{ 'update-notif.title' | transloco }}</h1>

        <p class="text-center text-4xl mb-0">
            {{version}}
        </p>
        <p class="text-center text-sm mt-0">
            {{transformDate(updatedAt)}}
        </p>
        <p class="mb-1 mt-2">{{ 'update-notif.description' | transloco }}</p>
        <button class="expepharma-mat-button-large mt-4 drop-shadow-md hover:drop-shadow-xl" type="button"
            mat-flat-button (click)="refreshPage()">
            {{ 'update-notif.refresh-page' | transloco }}
        </button>

    </div>
</div>

<div class="container-comment flex items-center content-center justify-center expepharma-ico-animation"
    (click)="openCommentZoho()">
    <div class="content-wrapper">
        <span class="flex items-center justify-center content-center">
            <mat-icon class="icon-size-4 mb-2 text-white" [svgIcon]="'heroicons_solid:annotation'">
            </mat-icon>{{"comments" | transloco}}
        </span>
    </div>
</div>
