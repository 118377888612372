import { NgModule } from '@angular/core';
import { ExpepharmaUtilsService } from '@expepharma/services/utils/utils.service';

@NgModule({
    providers: [ExpepharmaUtilsService],
})
export class ExpepharmaUtilsModule {
    /**
     * Constructor
     */
    constructor(private _expepharmaUtilsService: ExpepharmaUtilsService) {}
}
