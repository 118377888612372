import { Inject, Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { delay, Observable, of, switchMap, throwError } from 'rxjs';
import { ExpepharmaApiService } from '@expepharma/lib/api/api.service';
import { NavigationEnd, Router } from '@angular/router';
// import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ExpepharmaApiInterceptor implements HttpInterceptor {
    currentRoute: string = '/';

    /**
     * Constructor
     */
    constructor(
        private _expepharmaApiService: ExpepharmaApiService,
        private router: Router
    ) {}

    /**
     * Intercept
     *
     * @param request
     * @param next
     */
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const handler = this._expepharmaApiService.findHandler(
            request.method.toUpperCase(),
            request.url
        );

        if (!handler) {
            return next.handle(request);
        } else {
            const newParams: any = {
                url: request.url,
            };
            return next.handle(request.clone(newParams));
        }
    }
}
