import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ExpepharmaFullscreenComponent } from '@expepharma/components/fullscreen/fullscreen.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [ExpepharmaFullscreenComponent],
    imports: [MatButtonModule, MatIconModule, MatTooltipModule, CommonModule],
    exports: [ExpepharmaFullscreenComponent],
})
export class ExpepharmaFullscreenModule {}
