import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolver';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const wholeSaleRoutes: Route[] = [
    {
        path: '',
        data: {
            secure: true,
        },
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        // canLoad: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'secure/wholesale/home',
                data: {
                    name: 'Home admin Module',
                    description: 'Home module for the connected user',
                    // layout: 'modern',
                },
                loadChildren: () =>
                    import(
                        'app/modules/admin/wholesale/home-center/home-center.module'
                    ).then((m) => m.HomeCenterModule),
            },

            {
                path: 'secure/wholesale/account-validation',
                data: {
                    name: 'Account Validation after registration',
                    description:
                        'If registration not validated, the connected user have to send legal information',
                },
                loadChildren: () =>
                    import(
                        'app/modules/admin/account-validation/account-validation.module'
                    ).then((m) => m.AccountValidationModule),
            },
            {
                path: 'secure/wholesale/products-list',
                data: {
                    name: 'Products List Module',
                    description: 'List of Pharma and no-pharma products',
                    layout: 'modern',
                },
                loadChildren: () =>
                    import(
                        'app/modules/admin/wholesale/products-list/products-list.module'
                    ).then((m) => m.ProductsListModule),
            },
            {
                path: 'secure/wholesale/market-place',
                data: {
                    name: 'Market Place',
                    description: 'The market place of customer',
                    layout: 'modern',
                },
                loadChildren: () =>
                    import(
                        'app/modules/admin/wholesale/market-place/market-place.module'
                    ).then((m) => m.MarketPlaceModule),
            },
            {
                path: 'secure/wholesale/common/settings',
                data: {
                    name: 'Settings Module',
                    description: 'Module de paramétrages de la plateforme',
                    layout: 'modern',
                },
                loadChildren: () =>
                    import(
                        'app/modules/admin/common/settings/settings.module'
                    ).then((m) => m.SettingsModule),
            },
            {
                path: 'secure/wholesale/my-transactions',
                data: {
                    name: 'Transactions list Module',
                    description: 'Module de paramétrages de la plateforme',
                    layout: 'modern',
                },
                loadChildren: () =>
                    import(
                        'app/modules/admin/wholesale/orders/orders.module'
                    ).then((m) => m.OrdersModule),
            },
            {
                path: 'secure/wholesale/my-account',
                data: {
                    name: 'Account Module',
                    description: 'Module de gestion du compte',
                    layout: 'modern',
                },
                loadChildren: () =>
                    import('app/modules/admin/profile/profile.module').then(
                        (m) => m.ProfileModule
                    ),
            },
        ],
    },
];
