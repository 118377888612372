import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    CustomPercentPipe,
    EnumKeyPipe,
    ExpeCurrencyPipe,
    ExpeDatePipe,
    GoodChipForTypePipe,
    LocalizedDatePipe,
    StickyHeaderDirective,
} from 'app/core/global.helpers';

@NgModule({
    declarations: [
        GoodChipForTypePipe,
        EnumKeyPipe,
        StickyHeaderDirective,
        CustomPercentPipe,
        LocalizedDatePipe,
        ExpeCurrencyPipe,
        ExpeDatePipe,
    ],
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GoodChipForTypePipe,
        EnumKeyPipe,
        StickyHeaderDirective,
        CustomPercentPipe,
        LocalizedDatePipe,
        ExpeCurrencyPipe,
        ExpeDatePipe,
    ],
})
export class SharedModule {}
