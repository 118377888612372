<div class="flex flex-col max-w-240 max-h-screen -m-6">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{"validation-type-account-title-v2" | transloco}}</div>
    </div>

    <div class="w-full overflow-y-auto">
        <form class="flex flex-col flex-auto p-6 sm:p-8 pb-0" style="padding-bottom: 0px !important"
            [formGroup]="stepForm" #stepNgForm="ngForm" (submit)="sendDocument()" *ngIf="!isFinalLoading">

            <div class="w-full">

                <p class="text-xl lg:text-2xl font-medium text-center"><b>{{data.title | transloco}}</b></p>
                <mat-divider class="my-2"></mat-divider>
                <span>{{"message-selection-document" | transloco}}</span>

            </div>

            <div class="w-full">
                <p class="mt-3"><b><u>{{"documents-already-sent" | transloco}}</u></b>:</p>
                <ng-container *ngIf="(docsUser$ | async).length > 0; else noDocument">

                    <ul class="li-puce">
                        <li *ngFor="let doc of (docsUser$ | async)">
                            <b>{{doc.doc_type | transloco}}</b>
                            <br />
                            <span
                                class="m-1 py-0.5 px-2.5 rounded-full text-sm font-medium whitespace-nowrap text-green-800 bg-green-100"
                                style="cursor: pointer"><a (click)="downloadDocument(doc.doc_label)"
                                    style="cursor: pointer">{{doc.doc_label}}</a></span>
                            <button mat-mini-fab color="primary" type="button" [matMenuTriggerFor]="confirmRemoved">
                                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:trash'">
                                </mat-icon>
                            </button>

                            <mat-menu #confirmRemoved="matMenu">
                                <button mat-menu-item (click)="removeDocument(doc)">Confirmer la suppression</button>
                            </mat-menu>
                        </li>
                    </ul>
                </ng-container>
                <ng-template #noDocument>
                    <div class="p-4 sm:p-8 text-sm font-semibold text-center text-danger">
                        {{"no-document-uploaded" | transloco}}</div>
                </ng-template>
            </div>

            <div style="border-radius: 10px; border: 2px dashed rgba(0,0,0,0.2); padding: 15px" class="relative">

                <button mat-icon-button class="absolute" style="right: 0px; top: 0px"
                    *ngIf="stepForm.get('type').value !== null" (click)="resetFormStep()">
                    <mat-icon class="icon-size-3" [svgIcon]="'heroicons_outline:x'">
                    </mat-icon>
                </button>

                <div class="w-full" *ngIf="!isLoadingFile" style="padding-top: 20px;">
                    <mat-form-field class="w-full">
                        <mat-select [placeholder]="'selection-type-of-doc' | transloco" class="font-gilroy-light"
                            [formControlName]="'type'">
                            <mat-select-trigger>
                                <div class="flex" [innerHTML]="getSelectedValue()"></div>
                            </mat-select-trigger>
                            <mat-option *ngFor="let docType of docsList" [value]="docType.doc_type">
                                {{docType.doc_type | transloco}} <span
                                    class="m-1 py-0.5 px-2.5 rounded-full text-sm font-medium whitespace-nowrap text-red-800 bg-red-100"
                                    *ngIf="docType.is_mandatory === true"><b>{{"mandatory" | transloco}}</b></span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="flex mb-5" *ngIf="stepForm.get('type').value !== null">
                        <div class="w-full">
                            <input type="file" [formControlName]="'document_file'" class="file-input"
                                (change)="onFileSelected($event)" #fileUpload />

                            <div class="file-upload w-full text-center">
                                <p class="font-gilroy-light mb-1 text-center">
                                    <u>{{"document-choose-title" | transloco}}</u>
                                </p>
                                <button mat-mini-fab color="primary" type="button" class="upload-btn mr-2"
                                    (click)="fileUpload.click()">
                                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:paper-clip'">
                                    </mat-icon>

                                </button><br />
                                <span class="font-gilroy-light" [innerHTML]="getFileName()"></span>
                            </div>

                            <mat-error
                                *ngIf="formSubmitAttempt === true && stepForm.get('document_file').hasError('required')"
                                class="text-center">
                                <span class="font-gilroy-light"
                                    style="font-size: 12px">{{"wdl_number_file-missing-error1" | transloco}}</span>
                            </mat-error>
                            <mat-error
                                *ngIf="formSubmitAttempt === true && stepForm.get('document_file').hasError('type')"
                                class="text-center">
                                <span class="font-gilroy-light"
                                    style="font-size: 12px">{{"wdl_number_file-missing-error2" | transloco}}</span>
                            </mat-error>
                            <mat-error
                                *ngIf="formSubmitAttempt === true && stepForm.get('document_file').hasError('size')"
                                class="text-center">
                                <span class="font-gilroy-light"
                                    style="font-size: 12px">{{"wdl_number_file-missing-error3" | transloco}}</span>
                            </mat-error>

                        </div>

                    </div>
                    <div class="flex" *ngIf="stepForm.get('type').value !== null" style="padding: 12px">
                        <mat-form-field class="flex-auto">
                            <input matInput [formControlName]="'value'"
                                [placeholder]="'comment-value-upload-file' | transloco" class=" font-gilroy-light">
                            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:annotation'">
                            </mat-icon>
                        </mat-form-field>
                    </div>


                    <button *ngIf="stepForm.get('type').value !== null"
                        class="expepharma-mat-button-large w-full drop-shadow-md hover:drop-shadow-xl btn-hover"
                        style="margin-bottom: 20px" type="submit" mat-flat-button>
                        {{ 'add-document' | transloco }}
                    </button>

                </div>
                <div *ngIf="isLoadingFile" class="flex justify-center w-full text-center"> <svg class="spinner-expe"
                        width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                        <circle class="path-expe-green" fill="none" stroke="#5a7d7c" stroke-width="6"
                            stroke-linecap="round" cx="33" cy="33" r="30"></circle>
                    </svg></div>
            </div>

        </form>


        <div *ngIf="!isConformityUser" class="w-full text-center mb-5">
            <b>{{"pass-other-step" | transloco}}</b><br />-----------
        </div>

        <form class="flex flex-col flex-auto p-6 sm:p-8" [formGroup]="suscriptionForm" #suscriptionNgForm="ngForm"
            (submit)="validateAccount()" *ngIf="isConformityUser">

            <ng-container *ngIf="!isFinalLoading">
                <mat-divider class="my-2"></mat-divider>
                <p class="text-left mb-5 mt-5">
                    {{"message-selection-suscription-v2" | transloco}}</p>
            </ng-container>
            <!--

<p class="text-center mb-5 mt-5">
                <span
                    class="hover-chip m-1 py-0.5 px-2.5 rounded-full text-sm whitespace-nowrap text-blue-800 bg-blue-100"
                    (click)="openSuscription()">{{"details-each-suscription" | transloco}}</span>
            </p>
-->
            <!--
            <div style="border-radius: 10px; border: 2px dashed rgba(0,0,0,0.2); padding: 15px">

                <div class="w-full" *ngIf="!isFinalLoading">
                    <div class="flex flex-col mt-0 gt-sm:mt-0 gt-sm:ml-16">
                        <span class="font-semibold mb-2">{{"list-suscription" | transloco}}</span>
                        <mat-radio-group class="flex flex-col" [color]="'primary'" [formControlName]="'suscription'">
                            <mat-radio-button class="relative rounded-radio" [value]="'base'">
                                {{"suscription-type-one" | transloco}}

                                <button class="expepharma-mat-button drop-shadow-md hover:drop-shadow-xl btn-hover"
                                    style="position: absolute; top: 4px; right: 4px;" type="button" mat-flat-button
                                    (click)="openSuscription('base')">
                                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:information-circle'">
                                    </mat-icon>
                                </button>


                            </mat-radio-button>

                            <mat-radio-button class="relative rounded-radio" [disabled]="true" [value]="'premium'">
                                {{"suscription-type-two" | transloco}}

                                <button class="expepharma-mat-button drop-shadow-md hover:drop-shadow-xl btn-hover"
                                    style="position: absolute; top: 4px; right: 4px;" type="button" mat-flat-button
                                    (click)="openSuscription('premium')">
                                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:information-circle'">
                                    </mat-icon>
                                </button>
                            </mat-radio-button>
                            <mat-radio-button class="relative rounded-radio" [value]="'pro'" [disabled]="true">
                                {{"suscription-type-three" | transloco}}

                                <button class="expepharma-mat-button drop-shadow-md hover:drop-shadow-xl btn-hover"
                                    style="position: absolute; top: 4px; right: 4px;" type="button" mat-flat-button
                                    (click)="openSuscription('pro')">
                                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:information-circle'">
                                    </mat-icon>
                                </button>
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
             
                <div *ngIf="isFinalLoading" class="flex justify-center w-full text-center"> <svg class="spinner-expe"
                        width="40px" height="40px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                        <circle class="path-expe-green" fill="none" stroke="#5a7d7c" stroke-width="6"
                            stroke-linecap="round" cx="33" cy="33" r="30"></circle>
                    </svg></div>
            </div>
   -->
            <ng-container *ngIf="!isFinalLoading">
                <ng-container *ngIf="data.suscription === null">

                    <button
                        class="mat-mdc-button expepharma-mat-button-large w-full transition duration-150 ease-in-out hover:scale-95 hover:drop-shadow-xl hover:bg-[#12161a] bg-[#232c33] text-white"
                        style="margin-bottom: 20px; margin-top: 20px" type="submit" mat-flat-button>
                        {{ 'finalize-inscription' | transloco }}
                    </button>
                </ng-container>
                <ng-container *ngIf="data.suscription !== null">
                    <button
                        class="mat-mdc-button expepharma-mat-button-large w-full transition duration-150 ease-in-out hover:scale-95 hover:drop-shadow-xl hover:bg-[#12161a] bg-[#232c33] text-white"
                        style="margin-bottom: 20px; margin-top: 20px" type="submit" mat-flat-button>
                        {{ 'finalize-inscription-update' | transloco }}
                    </button>
                </ng-container>
            </ng-container>


            <ng-container *ngIf="isFinalLoading">
                <div class="w-full flex items-center content-center justify-center">
                    <mat-progress-spinner [diameter]="50" [mode]="'indeterminate'">
                    </mat-progress-spinner>
                </div>
            </ng-container>

        </form>

    </div>
</div>
