<!-- Loading bar -->
<expepharma-loading-bar></expepharma-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
    <expepharma-vertical-navigation class="dark bg-expepharma-dark print:hidden" [mode]="'over'"
        [name]="'mainNavigation'" [navigation]="navigation.default" [opened]="false">
        <!-- Navigation header hook -->
        <ng-container expepharmaVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex items-center justify-center h-20 pt-6 px-8 expepharma-ico-animation" style="text-align: center;
            margin-bottom: 20px;">
                <img class="drop-shadow-xl" src="assets/images/logo/logo-white-landing.svg" alt="Logo Expepharma"
                    style="width: 60px; min-width: 60px; max-width: 60px" routerLink="/">
            </div>
        </ng-container>
    </expepharma-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto items-center w-full min-w-0 bg-gray-200 dark:bg-card login-connection">

    <!-- Header -->
    <div class="flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden navbar"
        [class.is-sticky]="isSticky === true">
        <ng-container *ngIf="!isScreenSmall">
            <!-- Logo -->
            <div class="flex items-center mx-2 lg:mr-8">
                <div class="hidden lg:flex expepharma-ico-animation cursor-pointer">
                    <img class="dark:hidden drop-shadow-xl" src="assets/images/logo/logo-white-landing.svg"
                        style="width: 60px" routerLink="/">
                    <img class="hidden dark:flex w-24" src="assets/images/logo/logo-white-landing.svg" routerLink="/">
                </div>
                <img class="flex lg:hidden w-8" src="assets/images/logo/logo-white-landing.svg" routerLink="/">
            </div>
            <!-- Horizontal navigation -->
            <expepharma-horizontal-navigation class="mr-2" [name]="'mainNavigation'"
                [navigation]="navigation.horizontal">
            </expepharma-horizontal-navigation>
        </ng-container>
        <!-- Navigation toggle button -->
        <ng-container *ngIf="isScreenSmall">
            <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
        </ng-container>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">

            <switch></switch>
            <!--<search [appearance]="'bar'"></search>-->
            <notifications></notifications>
            <languages></languages>
            <user></user>
            <basket></basket>
        </div>
    </div>


    <!-- Content -->
    <div class="flex flex-auto justify-center w-full sm:p-6 md:p-8">
        <div
            class="flex flex-col flex-auto w-full sm:max-w-360 sm:shadow-lg sm:rounded-lg sm:overflow-hidden bg-default">
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
            <router-outlet *ngIf="true"></router-outlet>
        </div>
    </div>

    <!-- Footer -->
    <expepharma-footer class="w-full h-14"></expepharma-footer>
</div>
