import { Injectable } from '@angular/core';
import { assign, cloneDeep } from 'lodash-es';
import { ExpepharmaApiService } from '@expepharma/lib/api';
import { user as userData } from 'app/api/common/user/data';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class UserApi {
    private _user: any = userData;

    /**
     * Constructor
     */
    constructor(private _expepharmaApiService: ExpepharmaApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // localStorage.removeItem('accessToken');
        // localStorage.removeItem('refreshToken');
        // -----------------------------------------------------------------------------------------------------
        // @ User - GET
        // -----------------------------------------------------------------------------------------------------
        this._expepharmaApiService.onGet(environment.apiUrl + '/v1/oauth/user');
        // -----------------------------------------------------------------------------------------------------
        // @ User - PATCH
        // -----------------------------------------------------------------------------------------------------
        this._expepharmaApiService.onPatch(
            environment.apiUrl + '/v1/common/user'
        );
    }
}
