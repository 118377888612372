import { NgModule } from '@angular/core';
import { ExpepharmaMediaWatcherService } from '@expepharma/services/media-watcher/media-watcher.service';

@NgModule({
    providers: [ExpepharmaMediaWatcherService],
})
export class ExpepharmaMediaWatcherModule {
    /**
     * Constructor
     */
    constructor(
        private _expepharmaMediaWatcherService: ExpepharmaMediaWatcherService
    ) {}
}
