import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpepharmaDrawerComponent } from '@expepharma/components/drawer/drawer.component';

@NgModule({
    declarations: [ExpepharmaDrawerComponent],
    imports: [CommonModule],
    exports: [ExpepharmaDrawerComponent],
})
export class ExpepharmaDrawerModule {}
