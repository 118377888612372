<button
    class="expepharma-mat-button-large w-auto px-4 h-10 shadow-md hover:shadow-xl flex items-center justify-center content-center content"
    style=" border-radius: 20px;" (mouseenter)="onHover()" (mouseleave)="onLeave()" *ngIf="isEnabledForSwitch"
    [routerLink]="[switchPosition === 'pharma' ? '/secure/wholesale/home' : '/secure/pharma/home']">
    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:switch-vertical'"></mat-icon>

    <ul class="content__container__list" *ngIf="switchPosition === 'pharma'">
        <li #listItem1 class="content__container__list__item">{{"position-platform-pharma" | transloco}}</li>
        <li #listItem2 class="content__container__list__item content__container__list__item--hidden">
            {{"position-platform-wholesale" | transloco}}</li>
    </ul>

    <ul class="content__container__list" *ngIf="switchPosition === 'wholesale'">
        <li #listItem1 class="content__container__list__item">{{"position-platform-wholesale" | transloco}}</li>
        <li #listItem2 class="content__container__list__item content__container__list__item--hidden">
            {{"position-platform-pharma" | transloco}}
        </li>
    </ul>
</button>
<!--
    [matTooltip]="'switch plateforme'" 
    matTooltipPosition="above"
    matTooltipClass="expepharma-tooltip-bottom"
    !-->
