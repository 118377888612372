import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, filter, map, take, takeUntil } from 'rxjs';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { PusherService } from 'app/core/pusher/pusher.service';
import { Router } from '@angular/router';
import {
    LocalizedDatePipe,
    findChannelByName,
    subscribeToChannel,
} from 'app/core/global.helpers';
import * as moment from 'moment';
import { TranslocoService } from '@ngneat/transloco';
import { AuthUtils } from 'app/core/auth/auth.utils';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'notifications',
    providers: [LocalizedDatePipe],
})
export class NotificationsComponent implements OnInit, OnDestroy {
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel')
    private _notificationsPanel: TemplateRef<any>;

    notifications: Notification[] = [];
    unreadCount: number = 0;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private _cleanupPusher: () => void;
    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private localizedDate: LocalizedDatePipe,
        private _notificationsService: NotificationsService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _pusherService: PusherService,
        private _translocoService: TranslocoService,
        private _router: Router
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._notificationsService.notifications$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((notifications: Notification[]) => {
                this.notifications = notifications.map((notification) => {
                    if (notification.link && notification.useRouter) {
                        let processedLink = this.processLink(notification.link);
                        notification.path = processedLink.path;
                        notification.queryParams = processedLink.queryParams;
                    }

                    if (notification.description) {
                        let processedDescription = this.splitForTranslate(
                            notification.description
                        );
                        notification.descKey = processedDescription.key;
                        notification.descParams = processedDescription.params;
                    }
                    return notification;
                });
                this._calculateUnreadCount();
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }

        if (this._cleanupPusher) {
            this._cleanupPusher();
        }
    }

    timeElapsed(createdAt: string): string {
        const now = moment();
        const createdMoment = moment(createdAt);

        const diffInMinutes = now.diff(createdMoment, 'minutes');

        if (diffInMinutes < 60) {
            return this._translocoService.translate(
                diffInMinutes === 1 ? 'there-is-minute' : 'there-is-minutes',
                {
                    TIME: diffInMinutes,
                }
            );
        }

        const diffInHours = now.diff(createdMoment, 'hours');
        if (diffInHours < 24) {
            return this._translocoService.translate(
                diffInHours === 1 ? 'there-is-hour' : 'there-is-hours',
                {
                    TIME: diffInHours,
                }
            );
        }

        const diffInDays = now.diff(createdMoment, 'days');
        return this._translocoService.translate(
            diffInDays === 1 ? 'there-is-day' : 'there-is-days',
            {
                TIME: diffInDays,
            }
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    transformDate(date: string): string {
        return this.localizedDate.transform(date, 'short');
    }

    /**
     * Open the notifications panel
     */
    openPanel(): void {
        // Return if the notifications panel or its origin is not defined
        if (!this._notificationsPanel || !this._notificationsOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(
            new TemplatePortal(this._notificationsPanel, this._viewContainerRef)
        );
    }

    /**
     * Close the notifications panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): void {
        // Mark all as read
        this._notificationsService.markAllAsRead().subscribe();
    }

    /**
     * Toggle read status of the given notification
     */
    toggleRead(notification: Notification): void {
        // Toggle the read status
        notification.read = !notification.read;

        // Update the notification
        this._notificationsService
            .update(notification.id, notification)
            .subscribe();
    }

    /**
     * Delete the given notification
     */
    delete(notification: Notification): void {
        // Delete the notification
        this._notificationsService.delete(notification.id).subscribe();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'expepharma-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.noop(),
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(
                    this._notificationsOrigin._elementRef.nativeElement
                )
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom',
                    },
                ]),
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    private processLink(urlString: string) {
        let [slug, queryString] = urlString.split('?');
        let searchParams = new URLSearchParams(queryString);
        let queryParams = {};
        searchParams.forEach((value, key) => {
            queryParams[key] = value;
        });
        return { path: slug, queryParams };
    }

    private splitForTranslate(text: string): any {
        if (text.includes('?params=')) {
            const sp = text.split('?params=');
            return { key: sp[0], params: AuthUtils.fullAesDecode(sp[1]) };
        } else {
            return { key: text, params: {} };
        }
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void {
        let count = 0;

        if (this.notifications && this.notifications.length) {
            count = this.notifications.filter(
                (notification) => !notification.read
            ).length;
        }

        this.unreadCount = count;
    }

    removeAll() {
        this._notificationsService.deleteAll().subscribe();
    }
}
