import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { ExpepharmaHorizontalNavigationComponent } from '@expepharma/components/navigation/horizontal/horizontal.component';
import { ExpepharmaNavigationService } from '@expepharma/components/navigation/navigation.service';
import { ExpepharmaNavigationItem } from '@expepharma/components/navigation/navigation.types';
import { ExpepharmaUtilsService } from '@expepharma/services/utils/utils.service';

@Component({
    selector: 'expepharma-horizontal-navigation-basic-item',
    templateUrl: './basic.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpepharmaHorizontalNavigationBasicItemComponent
    implements OnInit, OnDestroy
{
    @Input() item: ExpepharmaNavigationItem;
    @Input() name: string;

    isActiveMatchOptions: IsActiveMatchOptions;
    private _expepharmaHorizontalNavigationComponent: ExpepharmaHorizontalNavigationComponent;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _expepharmaNavigationService: ExpepharmaNavigationService,
        private _expepharmaUtilsService: ExpepharmaUtilsService
    ) {
        // Set the equivalent of {exact: false} as default for active match options.
        // We are not assigning the item.isActiveMatchOptions directly to the
        // [routerLinkActiveOptions] because if it's "undefined" initially, the router
        // will throw an error and stop working.
        this.isActiveMatchOptions =
            this._expepharmaUtilsService.subsetMatchOptions;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Set the "isActiveMatchOptions" either from item's
        // "isActiveMatchOptions" or the equivalent form of
        // item's "exactMatch" option
        this.isActiveMatchOptions =
            this.item.isActiveMatchOptions ?? this.item.exactMatch
                ? this._expepharmaUtilsService.exactMatchOptions
                : this._expepharmaUtilsService.subsetMatchOptions;

        // Get the parent navigation component
        this._expepharmaHorizontalNavigationComponent =
            this._expepharmaNavigationService.getComponent(this.name);

        // Mark for check
        this._changeDetectorRef.markForCheck();

        // Subscribe to onRefreshed on the navigation component
        this._expepharmaHorizontalNavigationComponent.onRefreshed
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
