import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import {
    IHttpUser,
    ILegal,
    ITrade,
    IUserResponseLimitation,
    IUserResponseParamsSelling,
    TSuscription,
    TSuscriptionType,
    User,
} from 'app/core/user/user.types';
import { AuthUtils } from '../auth/auth.utils';
import { TUserAccountType } from '../global.types';
import { AlertService } from '../alerts/alert.service';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _alertService: AlertService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<User> {
        return this._httpClient
            .get<string>(environment.apiUrl + '/v1/oauth/user')
            .pipe(
                map((response) => {
                    const user: User = AuthUtils.fullAesDecode(response);
                    this._user.next(user);
                    return user;
                })
            );
    }

    /**
     * Update the user
     *
     * @param user
     */
    addSuscription(params: {
        suscription: TSuscription;
        type: TSuscriptionType;
        account: TUserAccountType;
    }): Observable<any> {
        return this._httpClient
            .patch<string>(environment.apiUrl + '/v1/oauth/suscription', {
                suscription: params.suscription,
                account: params.account,
                type: params.type,
            })
            .pipe(
                map((response) => {
                    const user: User = JSON.parse(
                        AuthUtils.aesDecode(response)
                    );
                    this._user.next(user);
                    return user;
                })
            );
    }

    /**
     * Update the user profile with the provided payload.
     *
     * Makes a PUT request to the API to update the user profile. It first maps the
     * payload to the request format, encodes it for security, then makes the request.
     *
     * On success, it updates the local user object and returns true. On failure,
     * it displays an alert and returns false.
     */
    update(payload): Observable<boolean> {
        return this.user$.pipe(
            take(1),
            switchMap((user) =>
                this._httpClient
                    .put<string>(environment.apiUrl + '/v1/user/update', {
                        payload: AuthUtils.fullAesEncode({
                            ...payload,
                        }),
                    })
                    .pipe(
                        map((result: string) => {
                            const res: IHttpUser =
                                AuthUtils.fullAesDecode(result);
                            if (res.success) {
                                this._user.next({ ...user, ...res.data });
                            } else {
                                this._alertService.liveAlert({
                                    appearance: 'soft',
                                    dismissed: false,
                                    showIcon: true,
                                    type: 'warning',
                                    position: 'bottom-center',
                                    message: res.message,
                                    clearTime: 5000,
                                });
                            }
                            return res.success;
                        })
                    )
            )
        );
    }

    uploadLogo(file: File): Observable<void> {
        const formData = new FormData();
        formData.append('logo', file);

        return this.user$.pipe(
            take(1),
            switchMap((user) =>
                this._httpClient
                    .post<string>(
                        environment.apiUrl + '/v1/company/logo',
                        formData
                    )
                    .pipe(
                        tap((response: string) => {
                            const logo: string =
                                AuthUtils.fullAesDecode(response);
                            this._user.next({
                                ...user,
                                legal: { ...user.legal, logo: logo },
                            });
                        }),
                        map(() => null)
                    )
            )
        );
    }

    removeLogo(): Observable<void> {
        return this.user$.pipe(
            take(1),
            switchMap((user) =>
                this._httpClient
                    .delete<string>(environment.apiUrl + '/v1/company/logo')
                    .pipe(
                        tap(() => {
                            this._user.next({
                                ...user,
                                legal: { ...user.legal, logo: null },
                            });
                        }),
                        map(() => null)
                    )
            )
        );
    }

    updateLegal(legal: any): Observable<void> {
        return this.user$.pipe(
            take(1),
            tap((user) => {
                this._user.next({
                    ...user,
                    legal: { ...user.legal, ...legal },
                });
            }),
            map(() => null)
        );
    }

    updatePhoneActivation(activation: boolean): Observable<void> {
        return this.user$.pipe(
            take(1),
            tap((user) => {
                this._user.next({
                    ...user,
                    phone_activation: activation,
                    status: 'active',
                });
            }),
            map(() => null)
        );
    }

    /**
     * Generates a secret key for two-factor authentication by making a request
     * to the API and decoding the response.
     *
     * @returns An Observable that emits the secret key as a string
     */
    generateSecretKey(): Observable<string> {
        return this._httpClient
            .post<string>(environment.apiUrl + '/v1/oauth/secret-2fa', {})
            .pipe(
                take(1),
                map((response: string) => {
                    const secret: string = AuthUtils.fullAesDecode(response);
                    return secret;
                })
            );
    }

    deleteLimitation(trade: ITrade): Observable<boolean> {
        return this.user$.pipe(
            take(1),
            switchMap((user) =>
                this._httpClient
                    .delete<string>(
                        environment.apiUrl + '/v1/user/limitation-trade',
                        {
                            params: {
                                payload: AuthUtils.fullAesEncode({
                                    state_id: trade.state_id,
                                }),
                            },
                        }
                    )
                    .pipe(
                        map((result: string) => {
                            const res: IUserResponseLimitation =
                                AuthUtils.fullAesDecode(result);
                            if (res.success) {
                                this._user.next({ ...user, trades: res.data });
                            } else {
                                this._alertService.liveAlert({
                                    appearance: 'soft',
                                    dismissed: false,
                                    showIcon: true,
                                    type: 'warning',
                                    position: 'bottom-center',
                                    message: res.message,
                                    clearTime: 5000,
                                });
                            }
                            return res.success;
                        })
                    )
            )
        );
    }

    addLimitation(id: number): Observable<boolean> {
        return this.user$.pipe(
            take(1),
            switchMap((user) =>
                this._httpClient
                    .post<string>(
                        environment.apiUrl + '/v1/user/limitation-trade',
                        {
                            payload: AuthUtils.fullAesEncode({
                                id: id,
                            }),
                        }
                    )
                    .pipe(
                        map((result: string) => {
                            const res: IUserResponseLimitation =
                                AuthUtils.fullAesDecode(result);
                            if (res.success) {
                                this._user.next({ ...user, trades: res.data });
                            } else {
                                this._alertService.liveAlert({
                                    appearance: 'soft',
                                    dismissed: false,
                                    showIcon: true,
                                    type: 'warning',
                                    position: 'bottom-center',
                                    message: res.message,
                                    clearTime: 5000,
                                });
                            }
                            return res.success;
                        })
                    )
            )
        );
    }

    setParameters(values: any): Observable<boolean> {
        return this.user$.pipe(
            take(1),
            switchMap((user) =>
                this._httpClient
                    .put<string>(
                        environment.apiUrl + '/v1/user/parameters-selling',
                        {
                            payload: AuthUtils.fullAesEncode({
                                ...values,
                            }),
                        }
                    )
                    .pipe(
                        map((result: string) => {
                            const res: IUserResponseParamsSelling =
                                AuthUtils.fullAesDecode(result);
                            if (res.success) {
                                this._user.next({
                                    ...user,
                                    legal: {
                                        ...user.legal,
                                        min_order:
                                            values.is_min_order === false
                                                ? null
                                                : values.min_order,
                                        max_order:
                                            values.is_max_order === false
                                                ? null
                                                : values.max_order,
                                        is_own_transport:
                                            values.is_own_transport,
                                        min_for_free_ship:
                                            values.is_own_transport === false
                                                ? null
                                                : values.min_for_free_ship,
                                        default_ship:
                                            values.is_own_transport === false
                                                ? null
                                                : values.default_ship,
                                        vat_ship:
                                            values.is_own_transport === false
                                                ? null
                                                : values.vat_ship / 100,
                                    },
                                });
                            } else {
                                this._alertService.liveAlert({
                                    appearance: 'soft',
                                    dismissed: false,
                                    showIcon: true,
                                    type: 'warning',
                                    position: 'bottom-center',
                                    message: res.message,
                                    clearTime: 5000,
                                });
                            }
                            return res.success;
                        })
                    )
            )
        );
    }
}
