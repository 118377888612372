import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ExpepharmaApiInterceptor } from '@expepharma/lib/api/api.interceptor';

@NgModule({
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ExpepharmaApiInterceptor,
            multi: true,
        },
    ],
})
export class ExpepharmaApiModule {
    /**
     * ExpepharmaMockApi module default configuration.
     *
     * @param apiServices - Array of services that register mock API handlers
     * @param config - Configuration options
     * @param config.delay - Default delay value in milliseconds to apply all responses
     */
    static forRoot(
        apiServices: any[],
        config?: { delay?: number }
    ): ModuleWithProviders<ExpepharmaApiModule> {
        return {
            ngModule: ExpepharmaApiModule,
            providers: [
                {
                    provide: APP_INITIALIZER,
                    deps: [...apiServices],
                    useFactory: () => (): any => null,
                    multi: true,
                },
            ],
        };
    }
}
