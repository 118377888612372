import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { ExpepharmaNavigationItem } from '@expepharma/components/navigation';
import { AuthUtils } from '../auth/auth.utils';
import { environment } from 'environments/environment';
@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private _navigation: ReplaySubject<Navigation> =
        new ReplaySubject<Navigation>(1);
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<string> {
        return this._httpClient
            .get<string>(environment.apiUrl + '/v1/oauth/navigation')
            .pipe(
                tap((response: string) => {
                    const navigation: ExpepharmaNavigationItem[] = JSON.parse(
                        AuthUtils.aesDecode(response)
                    );
                    this._navigation.next({
                        compact: navigation,
                        default: navigation,
                        futuristic: navigation,
                        horizontal: navigation,
                    });
                })
            );
    }
}
