import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { IFee } from '../global.types';
import { AuthUtils } from '../auth/auth.utils';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class FeesService {
    private _fees: BehaviorSubject<IFee[]> = new BehaviorSubject<IFee[]>([]);
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors link
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for Fees
     */
    get fees$(): Observable<IFee[]> {
        return this._fees.asObservable();
    }

    get(): Observable<IFee[]> {
        return this._httpClient
            .get(environment.apiUrl + '/v1/fees/details')
            .pipe(
                map((result: string) => {
                    const values: IFee[] = AuthUtils.fullAesDecode(result);
                    this._fees.next(values);
                    return values;
                })
            );
    }
}
