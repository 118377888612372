// popover.component.ts
import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'expepharma-popover',
    templateUrl: './popover.component.html',
    styleUrls: ['./popover.component.scss'],
})
export class ExpepharmaPopoverComponent implements OnInit {
    @Input() content: string;
    @Input() type: 'warning' | 'info' | 'success' | 'error' | 'classic' =
        'classic';
    constructor() {}

    ngOnInit(): void {}
}
