import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    BehaviorSubject,
    catchError,
    map,
    Observable,
    switchMap,
    take,
} from 'rxjs';
import { IDocsList, IDocsUser } from './document.types';
import { AuthUtils } from '../auth/auth.utils';
import { TUserAccountType } from '../global.types';
import { environment } from 'environments/environment';
@Injectable({
    providedIn: 'root',
})
export class DocumentService {
    private _docsUser: BehaviorSubject<IDocsUser[] | null> =
        new BehaviorSubject([]);
    private _docsList: BehaviorSubject<IDocsList[] | null> =
        new BehaviorSubject([]);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors link to Documents list of user
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for Document list
     */
    get docsUser$(): Observable<IDocsUser[]> {
        return this._docsUser.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors link to Listing of documents
    // -----------------------------------------------------------------------------------------------------
    /**
     * Getter for Document list
     */
    get docsList$(): Observable<IDocsList[]> {
        return this._docsList.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods link to Documents user & listing
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get All document link to the type
     */
    getListDocumentsByType(type: TUserAccountType): Observable<IDocsList[]> {
        return this._httpClient
            .get<string>(environment.apiUrl + '/v1/documents/lists', {
                params: {
                    type: AuthUtils.aesEncode(type),
                },
            })
            .pipe(
                map((result: string) => {
                    const values: IDocsList[] = JSON.parse(
                        AuthUtils.aesDecode(result)
                    );
                    this._docsList.next(values);
                    return values;
                })
            );
    }

    /**
     * Get All document link active
     */
    getAllActiveDocumentsList(): Observable<IDocsList[]> {
        return this._httpClient
            .get<string>(environment.apiUrl + '/v1/documents/full-lists', {})
            .pipe(
                map((result: string) => {
                    const values: IDocsList[] = JSON.parse(
                        AuthUtils.aesDecode(result)
                    );
                    this._docsList.next(values);
                    return values;
                })
            );
    }

    /**
     * Get User's documents
     */
    getListDocumentsByUser(): Observable<IDocsUser[]> {
        return this._httpClient
            .get<string>(environment.apiUrl + '/v1/documents/all', {})
            .pipe(
                map((result: string) => {
                    const values: IDocsUser[] = JSON.parse(
                        AuthUtils.aesDecode(result)
                    );
                    this._docsUser.next(values);
                    return values;
                })
            );
    }

    sendDocument(
        file: File,
        params: { type: string; value: string; label: string }
    ): Observable<any> {
        const option = AuthUtils.addOptionOtherGet('upload');

        const formData = new FormData();
        formData.append('pdf', file);
        formData.append('type', params.type);
        formData.append('value', params.value);

        return this.docsUser$.pipe(
            take(1),
            switchMap((docsUser) =>
                this._httpClient
                    .post<string>(
                        environment.apiUrl + '/v1/documents/upload',
                        formData,
                        option
                    )
                    .pipe(
                        map((values: string) => {
                            const newLine: IDocsUser = JSON.parse(
                                AuthUtils.aesDecode(values)
                            );
                            this._docsUser.next([...docsUser, newLine]);
                            return newLine;
                        }),
                        catchError((val) => {
                            return val;
                        })
                    )
            )
        );
    }

    removeDocument(doc: IDocsUser): Observable<IDocsUser> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                id: doc.id,
                filename: doc.doc_label,
            },
        };
        return this.docsUser$.pipe(
            take(1),
            switchMap((docsUser) =>
                this._httpClient
                    .delete<string>(
                        environment.apiUrl + '/v1/documents/delete',
                        options
                    )
                    .pipe(
                        map(() => {
                            this._docsUser.next([
                                ...docsUser.filter((e) => e.id !== doc.id),
                            ]);
                            return doc;
                        })
                    )
            )
        );
    }

    downloadDocument(filename: string): Observable<string> {
        return this._httpClient
            .get(environment.apiUrl + '/v1/documents/download', {
                params: {
                    filename: AuthUtils.aesEncode(filename),
                },
            })
            .pipe(
                map((result: string) => {
                    const path: string = JSON.parse(
                        AuthUtils.aesDecode(result)
                    );
                    return path;
                })
            );
    }
}
