import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { BasketDataResolver, InitialDataResolver } from 'app/app.resolver';
import { HomeResolver } from './modules/landing/home/home.resolver';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const pharmaRoutes: Route[] = [
    {
        path: '',
        data: {
            secure: true,
        },
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        // canLoad: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
            BasketDataResolver,HomeResolver,
        },
        children: [
            {
                path: 'secure/pharma/home',
                data: {
                    name: 'Home Pharma Module',
                    description: 'Home Pharma module for the connected user',
                },
                loadChildren: () =>
                    import(
                        'app/modules/admin/pharma/home-center/home-center.module'
                    ).then((m) => m.HomeCenterModule),
            },
            {
                path: 'secure/pharma/account-validation',
                data: {
                    name: 'Account Validation after registration',
                    description:
                        'If registration not validated, the connected user have to send legal information',
                },
                loadChildren: () =>
                    import(
                        'app/modules/admin/account-validation/account-validation.module'
                    ).then((m) => m.AccountValidationModule),
            },
            {
                path: 'secure/pharma/products-list',
                data: {
                    name: 'List of Pharma product',
                    description:
                        'List of Pharma product for the connected user',
                },
                loadChildren: () =>
                    import(
                        'app/modules/admin/pharma/products-list/products-list.module'
                    ).then((m) => m.ProductsListModule),
            },
            {
                path: 'secure/pharma/pharma-offers',
                data: {
                    name: 'List of Pharma Offers',
                    description: 'List of Pharma offers for the connected user',
                },
                loadChildren: () =>
                    import(
                        'app/modules/admin/pharma/pharma-offers/pharma-offers.module'
                    ).then((m) => m.PharmaOffersModule),
            },
            {
                path: 'secure/pharma/asking-product',
                data: {
                    name: 'List of Asking Product',
                    description:
                        'List of Asking Product for the connected user',
                },
                loadChildren: () =>
                    import(
                        'app/modules/admin/pharma/asking-product/asking-product.module'
                    ).then((m) => m.AskingProductModule),
            },
            {
                path: 'secure/pharma/market-place',
                data: {
                    name: 'Market Place Module',
                    description: 'Market place module for the connected user',
                },
                loadChildren: () =>
                    import(
                        'app/modules/admin/pharma/market-place/market-place.module'
                    ).then((m) => m.MarketPlaceModule),
            },
            {
                path: 'secure/pharma/pharma-orders',
                data: {
                    name: 'Orders Module',
                    description: 'Orders place module for the connected user',
                },
                loadChildren: () =>
                    import(
                        'app/modules/admin/pharma/pharma-orders/pharma-orders.module'
                    ).then((m) => m.PharmaOrdersModule),
            },
            {
                path: 'secure/pharma/full-cart',
                data: {
                    name: 'Cart Module',
                    description: 'Cart module for the connected user',
                },
                loadChildren: () =>
                    import(
                        'app/modules/admin/pharma/full-cart/full-cart.module'
                    ).then((m) => m.PharmaFullCartModule),
            },
            {
                path: 'secure/pharma/common/settings',
                data: {
                    name: 'Settings Module',
                    description: 'Module de paramétrages de la plateforme',
                    layout: 'modern',
                },
                loadChildren: () =>
                    import(
                        'app/modules/admin/common/settings/settings.module'
                    ).then((m) => m.SettingsModule),
            },
            {
                path: 'secure/pharma/my-account',
                data: {
                    name: 'Account Module',
                    description: 'Module de gestion du compte',
                    layout: 'modern',
                },
                loadChildren: () =>
                    import('app/modules/admin/profile/profile.module').then(
                        (m) => m.ProfileModule
                    ),
            },
        ],
    },
];
