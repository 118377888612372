import { IPagination } from 'app/core/global.types';
import { IProduct, IVat } from 'app/core/product/product.types';
import { TTypeAccount } from 'app/core/user/user.types';

export interface IHttpOrder extends IPagination {
    data: IOrder[];
}

export enum EnumDispatch {
    SALE = 'sale',
    BUY = 'buy',
}

export enum EnumOrderStatus {
    PROGRESS = 'progress',
    CANCELED = 'canceled',
    VALIDATED = 'validated',
}

export enum EnumOrderStatusStep {
    PENDING = 'pending',
    ACTIVE = 'active',
}

export enum EnumOrderType {
    CLASSIQUE = 'classique',
    SECURITY = 'security',
}

export enum EnumTypeClient {
    BUYER = 'buyer',
    SELLER = 'seller',
}

export enum EnumStepClient {
    INVOICE = 'invoice',
    STEP1 = 'step1',
    STEP2 = 'step2',
    STEP3 = 'step3',
    STEP4 = 'step4',
    STEP5 = 'step5',
}

export enum EnumPaymentType {
    BRIDGE = 'bridge',
    MANUAL = 'manual',
}

export enum EnumPaymentStatus {
    VALIDATED = 'validated',
    PROGRESS = 'progress',
    CANCELED = 'canceled',
    REJECTED = 'rejected',
    PENDING = 'pending',
}
export interface IOrder {
    id: number;
    hash: string;
    fee: number;
    fee_details: IFeeDetail;
    excl: number;
    incl: number;
    vat: number;
    vat_rate: number;
    owner_fee: EnumTypeClient;
    type: EnumOrderType;
    status: EnumOrderStatus;
    seller: IUserInfo;
    buyer: IUserInfo;
    product: IProduct;
    quotation: IOrderOffer;
    offer: IOrderOffer;
    exchanges?: IExchange[];
    payment?: IPayment;
    payments?: IPayment[];
    unread?: boolean;
    created_at: string;
    invoice_number?: string;
    steps?: IStep[];
}

export interface IFeeDetail {
    id: number;
    invoice_number: string;
    user_id: number;
    rate: number;
    excl: number;
    vat: number;
    vat_rate: number;
    incl: number;
    status: string;
}

export interface IStep {
    step: EnumStepClient;
    details: IStepDetails[];
}
export interface IStepDetails {
    user_id: number;
    type: EnumTypeClient;
    status: EnumOrderStatusStep;
    created_at: string;
}

export interface IMandatoryStep {
    step: EnumStepClient;
    description?: string;
    mandatory: EnumTypeClient[];
}

export interface IModalStep {
    step: EnumStepClient;
    mandatory: EnumTypeClient[];
    order: IOrder;
    provider: string;
}

export interface IModalStepWithVat extends IModalStep {
    vat: IVat;
}

export interface IExchange {
    id: number;
    order_id: number;
    value: string;
    read: boolean;
    is_attachment: boolean;
    reader_id: number;
    sender_id: number;
    created_at: string;
    updated_at: string;
}

export interface IUserInfo {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    participant_id: null;
    picture: null;
    account: TTypeAccount;
    legal: ILegalInfo;
    authorize_drop_off: boolean;
    is_laboratory: boolean;
}

export interface ILegalInfo {
    id: number;
    name: string;
    address_company: string;
    codepostal_company: string;
    city_company: string;
    country_company: string;
    registration: string;
    phone_company: string;
    logo: string;
}

export interface IOrderOffer {
    id: number;
    hash: string;
    created_at: string;
    price_seller?: number;
    price_buyer?: number;
    qty: number;
    min_batch: number;
    shelflife_type: string;
    shelflife: string;
    count_multiple: null;
    status: string;
    transport_prestation: boolean;
    insurance: boolean;
    is_multiple: boolean;
}

export interface IPayment {
    id: number;
    hash: string;
    type: EnumPaymentType;
    url: string;
    expiration: string;
    status: EnumPaymentStatus;
}

// export interface IOrderProduct {
//     id_produit: number;
//     libelle1: string;
//     libelle2: string;
//     libelle3: string;
//     code_eu: null;
//     code_country: string;
//     cip7: string;
//     cip7_bcb: number;
//     cip13: string;
//     ucd7: string;
//     ucd13: string;
//     cis: string;
//     is_tips_lpp: number;
//     is_homeo: number;
//     is_tfr: number;
//     is_stupefiant: number;
//     id_labo_titulaire: string;
//     id_labo_exploitant: string;
//     id_labo_distributeur: string;
//     code_tva: number;
//     prix_achat_ht: string;
//     prix_achat_ht_fabricant: string;
//     prix_vente_ttc: string;
//     code_acte: number;
//     code_liste: number;
//     code_stockage: number;
//     code_taux_ss: number;
//     base_remboursement_ss: string;
//     code_poids_volume: string;
//     poids_volume: string;
//     tpn: number;
//     peremption: number;
//     multiple_vente: number;
//     hauteur: number;
//     largeur: number;
//     longueur: number;
//     statut: number;
//     numero_amm: string;
//     date_amm: string;
//     agrement_collectivites: number;
//     otc: number;
//     attribut_casher: number;
//     type_conditionnement: number;
//     code_13_referent: number;
//     id_produit_remplacant: number;
//     code_index_acl: string;
//     dispositif_medical: number;
//     medicament_specifique: string;
//     serialisation: number;
//     code_ecotaxe: string;
//     para_hospitalier: number;
//     poids_volume_decimal: number;
//     date_creation: string;
//     prix_achat_ht_fabricant_date: string;
//     spf: number;
//     created_at: null;
//     updated_at: null;
//     infos: IProductInfo;
//     familles: IProductCategory[];
// }

// export interface IProductCategory {
//     code_famille: string;
//     libelle_famille: string;
//     created_at: null;
//     updated_at: null;
//     pivot: {
//         id_produit: number;
//         code_famille: string;
//     };
// }

// export interface IProductInfo {
//     id_produit: number;
//     libelle: string;
//     libelle2: string;
//     id_type: number;
//     id_categorie: number;
//     id_type_categorie: number;
//     is_referent_generique: number;
//     is_dopant: number;
//     code_pr1: number;
//     code_pr2: number;
//     code_pr3: number;
//     code_pr4: number;
//     code_pr5: number;
//     code_pr6: number;
//     medicament_exception: number;
//     medicament_t2a: number;
//     supprime: string;
//     surveillance_renforcee: number;
//     molecule_onereuse: number;
//     is_referent_biosimilaire: number;
//     created_at: string;
//     updated_at: string;
// }

// export interface Profile {
//     id?: string;
//     name?: string;
//     email?: string;
//     avatar?: string;
//     about?: string;
// }

// export interface Contact {
//     id?: string;
//     avatar?: string;
//     name?: string;
//     about?: string;
//     details?: {
//         emails?: {
//             email?: string;
//             label?: string;
//         }[];
//         phoneNumbers?: {
//             country?: string;
//             phoneNumber?: string;
//             label?: string;
//         }[];
//         title?: string;
//         company?: string;
//         birthday?: string;
//         address?: string;
//     };
//     attachments?: {
//         media?: any[];
//         docs?: any[];
//         links?: any[];
//     };
// }
