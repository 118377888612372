import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { UserService } from '../user/user.service';
import Pusher from 'pusher-js';
import { IChannel } from './pusher.types';

@Injectable({
    providedIn: 'root',
})
export class PusherService {
    pusher: any;
    private _channels: BehaviorSubject<IChannel[]> = new BehaviorSubject<
        IChannel[]
    >([]);

    /**
     * Constructor
     */
    constructor(private _userService: UserService) {
        this.init();
        // this.pusher = new Pusher(environment.pusher.key, {
        //     cluster: environment.pusher.cluster,
        // });

        // this._userService.user$.pipe(take(1)).subscribe((e) => {
        //     this._channels.next([
        //         ...this._channels.getValue(),
        //         {
        //             name: 'userChannel',
        //             channel: this.pusher.subscribe('user' + e.id),
        //         },
        //         {
        //             name: 'pharmaActionChannel',
        //             channel: this.pusher.subscribe('realtime_action'),
        //         },
        //     ]);

        //     this._channels.next([
        //         ...this._channels.getValue(),
        //         {
        //             name: 'publicChannel',
        //             channel: this.pusher.subscribe('notification'),
        //         },
        //     ]);
        // });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get channels$(): Observable<IChannel[]> {
        return this._channels.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    public init(): void {
        this.pusher = new Pusher(environment.pusher.key, {
            cluster: environment.pusher.cluster,
        });

        this._userService.user$.pipe(take(1)).subscribe((user) => {
            this._channels.next([
                ...this._channels.getValue(),
                {
                    name: 'userChannel',
                    channel: this.pusher.subscribe('user' + user.id),
                },
                {
                    name: 'pharmaActionChannel',
                    channel: this.pusher.subscribe('realtime_action'),
                },
                {
                    name: 'publicChannel',
                    channel: this.pusher.subscribe('notification'),
                },
            ]);
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors link
    // -----------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors link to Emails side
    // -----------------------------------------------------------------------------------------------------
}
