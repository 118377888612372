import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, takeUntil } from 'rxjs';
import {
    EnumBasketType,
    IBasket,
    IBasketGroupUser,
} from 'app/layout/common/basket/basket.types';
import { BasketService } from 'app/layout/common/basket/basket.service';
import { LocalizedDatePipe } from 'app/core/global.helpers';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { set } from 'lodash';
import { TranslocoService } from '@ngneat/transloco';
import { ExpepharmaPopoverService } from 'app/core/popover/popover.service';

@Component({
    selector: 'basket',
    templateUrl: './basket.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'basket',
    providers: [LocalizedDatePipe],
})
export class BasketComponent implements OnInit, OnDestroy {
    @ViewChild('itemsOrigin') private _itemsOrigin: MatButton;
    @ViewChild('itemsPanel')
    private _itemsPanel: TemplateRef<any>;
    private itemTimers = new Map<number, any>();
    readonly EnumBasketType = EnumBasketType;
    totalItem: number = 0;
    items: IBasket[] = [];
    itemsClassic: IBasketGroupUser[] = [];
    itemsThreshold: IBasketGroupUser[] = [];
    isEnabled: boolean = false;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    hashSession: string = null;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private localizedDate: LocalizedDatePipe,
        private _basketService: BasketService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _expepharmaPopoverService: ExpepharmaPopoverService,
        private _transloco: TranslocoService
    ) {
        if (
            'pharma' ===
            AuthUtils.aesDecode(AuthUtils.checkUrl(window.location.href))
        ) {
            this.isEnabled = true;
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._basketService.items$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((items: IBasket[]) => {
                this.items = items;
                this.itemsClassic = this.groupUser(this.EnumBasketType.CLASSIC);
                this.itemsThreshold = this.groupUser(
                    this.EnumBasketType.THRESHOLD
                );

                setTimeout(() => {
                    this._calculateCountItems();
                    this._changeDetectorRef.markForCheck();
                }, 1000);
            });

        this._basketService.sessionHash$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((hash: string) => {
                this.hashSession = hash;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }

        this.itemTimers.forEach((timer) => clearTimeout(timer));
        this.itemTimers.clear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'expepharma-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.noop(),
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(
                    this._itemsOrigin._elementRef.nativeElement
                )
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom',
                    },
                ]),
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateCountItems(): void {
        this.totalItem = this.items.length;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Groups the basket items by user.
     *
     * @returns An array of basket item groups, where each group contains the user
     * and the basket details for that user.
     */
    groupUser(type: EnumBasketType): IBasketGroupUser[] {
        return this.items
            .filter((e) => e.basket.type === type)
            .reduce((acc, item) => {
                let total = acc.length;
                let index = acc.findIndex(
                    (x) => x.user.id === item.offer.user.id
                );
                if (index === -1) {
                    acc.push({
                        user: item.offer.user,
                        details: [],
                    });
                    index = total;
                }

                acc[index].details.push(item);
                return acc;
            }, [] as IBasketGroupUser[]);
    }

    /**
     * Formats the given date string to a short date format using the localizedDate service.
     *
     * @param date - The date string to format.
     * @returns The formatted short date string.
     */
    transformDate(date: string): string {
        return this.localizedDate.transform(date, 'short');
    }

    /**
     * Open the items panel
     */
    openPanel(): void {
        if (!this._itemsPanel || !this._itemsOrigin) {
            return;
        }

        if (!this._overlayRef) {
            this._createOverlay();
        }

        this._overlayRef.attach(
            new TemplatePortal(this._itemsPanel, this._viewContainerRef)
        );
    }

    /**
     * Close the items panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    /**
     * Calculates the total price for all items in the basket.
     *
     * Iterates through each item in the basket, multiplying the
     * price by the quantity to get the total for that item.
     * Sums all item totals to calculate overall basket total.
     *
     * @returns The total price for all items in the basket.
     */
    calculateAmount(type?: EnumBasketType) {
        let amount = 0;

        if (!type) {
            this.items.forEach((item) => {
                amount += item.price * item.qty;
            });
        }

        if (type && type === EnumBasketType.CLASSIC) {
            this.itemsClassic.forEach((item) => {
                item.details.forEach((it) => {
                    amount += it.price * it.qty;
                });
            });
        }

        if (type && type === EnumBasketType.THRESHOLD) {
            this.itemsThreshold.forEach((item) => {
                item.details.forEach((it) => {
                    amount += it.price * it.qty;
                });
            });
        }

        return amount;
    }

    /**
     * Removes an item from the basket.
     *
     * @param offer_id - The offer ID of the item to remove.
     * @param product_id - The product ID of the item to remove.
     * @param hash - The unique hash of the item to remove.
     */
    removeItem(offer_id: number, product_id: number, hash: string): void {
        this._basketService
            .removeFromBasket(offer_id, product_id, hash)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * Opens a warning popover if the checkout is blocked, to notify the user.
     *
     * @param event - The mouse event that triggered the popover.
     */
    showPopover(event: MouseEvent) {
        if (this.hashSession !== null) {
            if (!this._expepharmaPopoverService.isOpen()) {
                const content = this._transloco.translate(
                    'offer.warning-checkout-blocked'
                );

                this._expepharmaPopoverService.open(
                    {
                        origin: event.target,
                        content: content,
                    },
                    'warning'
                );
            }
        }
    }

    /**
     * Closes the popover opened by this service.
     */
    hidePopover() {
        this._expepharmaPopoverService.close();
    }

    minimaOrder(item: IBasketGroupUser): number {
        if (item?.user?.legal?.min_order) {
            if (item?.user?.legal?.min_order !== null) {
                return item?.user.legal.min_order;
            }
        }
        return null;
    }

    checkMinima(item: IBasketGroupUser): boolean {
        if (item.user?.legal?.min_order) {
            if (item.user.legal.min_order !== null) {
                const min = Number(item.user.legal.min_order);
                const total = item.details.reduce((acc, item) => {
                    return acc + item.price * item.qty;
                }, 0);

                return min <= total ? true : false;
            }
        }
        return true;
    }

    maximaOrder(item: IBasketGroupUser): number {
        if (item?.user?.legal?.max_order) {
            if (item?.user?.legal?.max_order !== null) {
                return item?.user.legal.max_order;
            }
        }
        return null;
    }

    checkMaxima(item: IBasketGroupUser): boolean {
        if (item.user?.legal?.max_order) {
            if (item.user.legal.max_order !== null) {
                const max = Number(item.user.legal.max_order);
                const total = item.details.reduce((acc, item) => {
                    return acc + item.price * item.qty;
                }, 0);

                return max >= total ? true : false;
            }
        }
        return true;
    }
}
