import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { Subject } from 'rxjs';

@Component({
    selector: 'expepharma-footer',
    styleUrls: ['./footer.component.scss'],
    templateUrl: './footer.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ExpepharmaFooterComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    userTypesList: string;
    VideTypes: string[];
    isPharmaType: boolean = false;

    /**
     * Constructor
     */
    constructor(public route: ActivatedRoute) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        if (
            'pharma' ===
            AuthUtils.aesDecode(AuthUtils.checkUrl(window.location.href))
        ) {
            this.isPharmaType = true;
        } else {
            this.isPharmaType = false;
        }
    }

    /**
     * After view init
     */
    ngAfterViewInit(): void {}

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
