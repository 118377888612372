import {
    Translation,
    TRANSLOCO_CONFIG,
    TRANSLOCO_LOADER,
    translocoConfig,
    TranslocoModule,
    TranslocoService,
    getBrowserCultureLang,
} from '@ngneat/transloco';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslocoHttpLoader } from 'app/core/transloco/transloco.http-loader';
import {
    TRANSLOCO_PERSIST_LANG_STORAGE,
    TranslocoPersistLangModule,
} from '@ngneat/transloco-persist-lang';
import {
    ExpepharmaNavigationService,
    ExpepharmaVerticalNavigationComponent,
} from '@expepharma/components/navigation';

const lngArray = [
    {
        id: 'en',
        label: 'English',
    },
    {
        id: 'fr',
        label: 'Français',
    },
    {
        id: 'de',
        label: 'Deutsch',
    },
    {
        id: 'ar',
        label: 'العربية',
    },
    {
        id: 'it',
        label: 'Italia',
    },
];

@NgModule({
    imports: [
        TranslocoPersistLangModule.forRoot({
            storage: {
                provide: TRANSLOCO_PERSIST_LANG_STORAGE,
                useValue: localStorage,
            },
        }),
    ],
    exports: [TranslocoModule],
    providers: [
        {
            // Provide the default Transloco configuration
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: lngArray,
                defaultLang: 'en',
                fallbackLang: 'en',
                reRenderOnLangChange: true,
                prodMode: true,
            }),
        },
        {
            // Provide the default Transloco loader
            provide: TRANSLOCO_LOADER,
            useClass: TranslocoHttpLoader,
        },
        {
            // Preload the default language before the app starts to prevent empty/jumping content
            provide: APP_INITIALIZER,
            deps: [TranslocoService, ExpepharmaNavigationService],
            useFactory:
                (
                    _translocoService: TranslocoService,
                    _expepharmaNavigationService: ExpepharmaNavigationService
                ): any =>
                (): Promise<Translation> => {
                    const lngStorage = localStorage.getItem('translocoLang');
                    let defaultLang = _translocoService.getDefaultLang();
                    if (lngStorage) {
                        defaultLang = lngStorage;
                    } else {
                        const browserLangue = getBrowserCultureLang();
                        if (browserLangue.split('-').length > 1) {
                            const lng = browserLangue.split('-')[0];
                            if (
                                lngArray.find((e) => e.id === lng) !== undefined
                            ) {
                                defaultLang = lng;
                            }
                        }
                    }
                    _translocoService.setActiveLang(defaultLang);
                    return _translocoService.load(defaultLang).toPromise();
                },
            multi: true,
        },
    ],
})
export class TranslocoCoreModule {}
