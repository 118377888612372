import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ExpepharmaAlertModule } from '@expepharma/components/alert';
import { SharedModule } from 'app/shared/shared.module';
import { ExpepharmaCardModule } from '@expepharma/components/card';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ValidationAccountComponent } from './validation-account.component';

@NgModule({
    declarations: [ValidationAccountComponent],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: ['products', 'categories', 'offer'],
        },
    ],
    imports: [
        MatButtonModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        ExpepharmaAlertModule,
        SharedModule,
        ExpepharmaCardModule,
        TranslocoModule,
        MatSelectModule,
        MatDialogModule,
        MatMenuModule,
        MatRadioModule,
        MatDividerModule,
        MatTooltipModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
    ],
})
export class ValidationAccountModule {}
