   <!-- Footer -->
   <div class="relative flex items-center w-full h-auto px-4 border-t bg-card print:hidden content-center justify-center pt-8 pb-2"
       style="background: #232c33 !important;">

       <div class="flex flex-col flex-auto w-full">
           <div class="flex flex-col items-center mb-2">
               <div class="grid grid-cols-1 gap-6 w-full">
                   <div class="flex items-center content-center justify-center w-full">
                       <div class="logo-hover"><a class="text-white"
                               [routerLink]="['/home/private-policy']">{{"landing.footer.policies-confident" | transloco}}</a>
                       </div>
                       <span class="mr-2 ml-2">|</span>
                       <div class="logo-hover" *ngIf="isPharmaType"><a class="text-white"
                               [routerLink]="['/home/terms-conditions-pharma']">{{"landing.footer.terms-conditions" | transloco}}</a>
                       </div>

                       <div class="logo-hover" *ngIf="!isPharmaType"><a class="text-white"
                               [routerLink]="['/home/terms-conditions-wholesale']">{{"landing.footer.terms-conditions" | transloco}}</a>
                       </div>
                       <span class="mr-2 ml-2">|</span>
                       <div class="logo-hover"><a class="text-white"
                               [routerLink]="['/home/legal-notice']">{{"landing.footer.legal-mention" | transloco}}</a>
                       </div>
                       <span class="mr-2 ml-2">|</span>
                       <div class="logo-hover"><a class="text-white"
                               [routerLink]="['/home/cookies-policy']">{{"landing.footer.policies-cookies" | transloco}}</a>
                       </div>



                   </div>
               </div>
           </div>
           <div class="flex flex-col items-center mb-2 mt-2">
               <div class="grid grid-cols-1 w-full">
                   <div class="flex items-center content-center justify-center w-full text-white">
                       <div>Expepharma &copy; {{currentYear}}</div>
                   </div>
               </div>
           </div>

           <div class="flex flex-col items-center mt-2">
               <div class="grid grid-cols-1 w-full">
                   <div class="flex items-center content-center justify-center w-full">
                       <img class="mr-2" src="assets/images/logo/bridge-logo.png" alt="bridge"
                           style="width: 80px !important; height: 40px" />
                       <img class="mr-2 ml-2 rounded" src="assets/images/logo/bp-logo.jpg" alt="banque populaire"
                           style="width: 80px !important; height: 40px" />
                       <img class="ml-2" src="assets/images/logo/mollie-logo.png" alt="mangopay"
                           style="width: 80px !important; width: 40px" />
                   </div>
               </div>
           </div>

       </div>
