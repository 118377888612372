import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { compact, fromPairs } from 'lodash-es';
import { ExpepharmaApiHandler } from '@expepharma/lib/api/api.request-handler';
import { ExpepharmaApiMethods } from '@expepharma/lib/api/api.types';

@Injectable({
    providedIn: 'root',
})
export class ExpepharmaApiService {
    private _handlers: { [key: string]: Map<string, ExpepharmaApiHandler> } = {
        get: new Map<string, ExpepharmaApiHandler>(),
        post: new Map<string, ExpepharmaApiHandler>(),
        patch: new Map<string, ExpepharmaApiHandler>(),
        delete: new Map<string, ExpepharmaApiHandler>(),
        put: new Map<string, ExpepharmaApiHandler>(),
        head: new Map<string, ExpepharmaApiHandler>(),
        jsonp: new Map<string, ExpepharmaApiHandler>(),
        options: new Map<string, ExpepharmaApiHandler>(),
    };
    /**
     * Constructor
     */
    constructor() {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Find the handler from the service
     * with the given method and url
     *
     * @param method
     * @param url
     */
    findHandler(method: string, url: string): ExpepharmaApiHandler | undefined {
        // Prepare the return object
        let matchingHandler: ExpepharmaApiHandler | undefined;

        // Split the url
        const urlParts = url.split('/');

        // Get all related request handlers
        const handlers = this._handlers[method.toLowerCase()];

        // Iterate through the handlers
        handlers.forEach((handler, handlerUrl) => {
            // Skip if there is already a matching handler
            if (matchingHandler) {
                return;
            }

            // Split the handler url
            const handlerUrlParts = handlerUrl.split('/');

            // Skip if the lengths of the urls we are comparing are not the same
            if (urlParts.length !== handlerUrlParts.length) {
                return;
            }

            // Compare
            const matches = handlerUrlParts.every(
                (handlerUrlPart, index) =>
                    handlerUrlPart === urlParts[index] ||
                    handlerUrlPart.startsWith(':')
            );

            // If there is a match...
            if (matches) {
                // Assign the matching handler
                matchingHandler = handler;
            }
        });
        return matchingHandler;
    }

    /**
     * Register GET request handler
     *
     * @param url - URL address of the mocked API endpoint
     * @param delay - Delay of the response in milliseconds
     */
    onGet(url: string): ExpepharmaApiHandler {
        return this._registerHandler('get', url);
    }

    /**
     * Register POST request handler
     *
     * @param url - URL address of the mocked API endpoint
     * @param delay - Delay of the response in milliseconds
     */
    onPost(url: string): ExpepharmaApiHandler {
        return this._registerHandler('post', url);
    }

    /**
     * Register PATCH request handler
     *
     * @param url - URL address of the mocked API endpoint
     * @param delay - Delay of the response in milliseconds
     */
    onPatch(url: string): ExpepharmaApiHandler {
        return this._registerHandler('patch', url);
    }

    /**
     * Register DELETE request handler
     *
     * @param url - URL address of the mocked API endpoint
     * @param delay - Delay of the response in milliseconds
     */
    onDelete(url: string): ExpepharmaApiHandler {
        return this._registerHandler('delete', url);
    }

    /**
     * Register PUT request handler
     *
     * @param url - URL address of the mocked API endpoint
     * @param delay - Delay of the response in milliseconds
     */
    onPut(url: string): ExpepharmaApiHandler {
        return this._registerHandler('put', url);
    }

    /**
     * Register HEAD request handler
     *
     * @param url - URL address of the mocked API endpoint
     * @param delay - Delay of the response in milliseconds
     */
    onHead(url: string): ExpepharmaApiHandler {
        return this._registerHandler('head', url);
    }

    /**
     * Register JSONP request handler
     *
     * @param url - URL address of the mocked API endpoint
     * @param delay - Delay of the response in milliseconds
     */
    onJsonp(url: string): ExpepharmaApiHandler {
        return this._registerHandler('jsonp', url);
    }

    /**
     * Register OPTIONS request handler
     *
     * @param url - URL address of the mocked API endpoint
     * @param delay - Delay of the response in milliseconds
     */
    onOptions(url: string, delay?: number): ExpepharmaApiHandler {
        return this._registerHandler('options', url);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register and return a new instance of the handler
     *
     * @param method
     * @param url
     * @param delay
     * @private
     */
    private _registerHandler(
        method: ExpepharmaApiMethods,
        url: string
    ): ExpepharmaApiHandler {
        // Create a new instance of ExpepharmaMockApiRequestHandler
        const expepharmaHttp = new ExpepharmaApiHandler(url);
        // Store the handler to access it from the interceptor
        this._handlers[method].set(url, expepharmaHttp);
        // Return the instance
        return expepharmaHttp;
    }
}
