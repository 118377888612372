import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
// import { Integrations } from '@sentry/tracing';
import { AppModule } from 'app/app.module';
import { environment } from 'environments/environment';

if (environment.production) {
    if (environment.envType === 'production') {
        window.console.log = function () {};
    }

    enableProdMode();
    Sentry.init({
        dsn: 'https://c79f3fda9fd540148b54ffbad385b5d7@o4505430539173888.ingest.sentry.io/4505430541271040',
        integrations: [
            new Sentry.BrowserTracing({
                tracingOrigins: ['localhost', 'expepharma.com'],
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
        ],
        tracesSampleRate: 0.2,
        replaysSessionSampleRate: 0.05,
    });
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
