<div class="flex flex-col max-w-240 max-h-screen -m-6">

    <!-- Header -->
    <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
        <div class="text-lg font-medium">{{"validation-account" | transloco}}</div>
    </div>

    <div class="w-full overflow-y-auto p-10">

        <div class="mt-2">
            <div class="w-full" [innerHTML]="explanationChoiceTypeValidation"></div>

            <div class="flex items-center leading-5 text-md text-secondary my-[10px]">
                <button
                    class="mat-mdc-button expepharma-mat-button-large w-full transition duration-150 ease-in-out hover:scale-95 hover:drop-shadow-xl hover:bg-[#12161a] bg-[#232c33] text-white"
                    type="button" mat-flat-button (click)="goToPhoneValidation()">
                    {{ 'go-to-phone-validation' | transloco }}
                </button>
            </div>
            <div class="flex items-center leading-5 text-md text-secondary my-[10px]">
                <button
                    class="mat-mdc-button expepharma-mat-button-large w-full transition duration-150 ease-in-out hover:scale-95 hover:drop-shadow-xl hover:bg-[#425c5b] bg-[#5a7d7c] text-white"
                    type="button" mat-flat-button (click)="gotToManualValidation()">
                    {{ 'go-to-manual-validation' | transloco }}
                </button>
            </div>

        </div>

    </div>
</div>
