import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
/**
 * Manages timers stored in a map, with the ability to set and clear them.
 *
 * setTimer() sets a timer with an ID, expiration time, and callback.
 * It clears any existing timer with the same ID, calculates the duration
 * until expiration, and sets a timeout to trigger the callback.
 *
 * clearTimer() clears the timer with the given ID by removing it from
 * the map and clearing the timeout.
 */
export class ExpepharmaTimerService {
    private timers = new Map<string, { timer: any; expireAt: moment.Moment }>();

    constructor() {}

    /**
     * Sets a timer with the given ID, expiration time, and callback.
     * Clears any existing timer with the same ID.
     * Calculates the duration until the expiration time based on the current UTC time.
     * If the duration is <= 0, triggers the callback immediately.
     * Otherwise, sets a timeout to trigger the callback after the calculated duration.
     */
    setTimer(id: string, expireAt: moment.Moment, callback: () => void): void {
        this.clearTimer(id);

        const now = moment.utc();
        const expiration = expireAt;
        const duration = expiration.diff(now);

        if (duration <= 0) {
            setTimeout(callback, 0);
        } else {
            const timer = setTimeout(() => {
                callback();
                this.timers.delete(id);
            }, duration);

            this.timers.set(id, { timer, expireAt: expiration });
        }
    }

    /**
     * Clears the timer with the given ID.
     * Removes the timer and callback from the timers map.
     */
    clearTimer(id: string): void {
        if (this.timers.has(id)) {
            const { timer } = this.timers.get(id);
            clearTimeout(timer);
            this.timers.delete(id);
        }
    }
}
