<!-- Notifications toggle -->
<button mat-icon-button (click)="openPanel()" #itemsOrigin class="expepharma-ico-animation" *ngIf="isEnabled"
    id="basket-container">
    <ng-container *ngIf="totalItem > 0">
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3 z-10">
            <span
                class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-[#232c33] text-white text-xs font-medium">
                {{totalItem}}
            </span>
        </span>
    </ng-container>
    <mat-icon [svgIcon]="'heroicons_outline:shopping-cart'"></mat-icon>
</button>

<!-- Notifications panel -->
<ng-template #itemsPanel>

    <div
        class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

        <!-- Header -->
        <div class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary">
            <div class="sm:hidden -ml-1 mr-3">
                <button mat-icon-button (click)="closePanel()">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
            <div class="text-lg font-medium leading-10">{{"basket.basket-title" | transloco}}</div>
            <div class="ml-auto font-bold text-xl">
                {{calculateAmount() | expeCurrency}}
            </div>
        </div>

        <ng-container *ngIf="items && items.length > 0; else noBasket">
            <div class="relative flex flex-col flex-auto sm:max-h-120 overflow-y-auto bg-card">
                <ng-container *ngIf="itemsClassic.length > 0">
                    <p class="font-semibold mt-4 text-center"
                        [innerHTML]="'basket.classic-product-title' | transloco: {AMOUNT: calculateAmount(EnumBasketType.CLASSIC) | expeCurrency}">
                    </p>
                    <ng-container *ngFor="let item of itemsClassic; trackBy: trackByFn">
                        <div class="flex group hover:bg-gray-50">
                            <div class="flex flex-auto py-5 pl-6">
                                <div class="flex flex-col flex-auto">
                                    <div
                                        class="font-semibold lined-clamp-1 px-2 py-2 bg-[#232c33] rounded-lg mr-4 text-white flex flex-auto justify-start ">
                                        <img src="assets/images/flags/{{item.user?.legal?.country_company|| 'FR'}}.svg"
                                            alt="country" class="mr-2 rounded" style="width: 20px; height: 16px" />
                                        <display-customer [user]="item?.user" [withoutIcon]="true"></display-customer>
                                    </div>
                                    <div *ngIf="minimaOrder(item) !== null"
                                        class=" text-[11px] lined-clamp-1 py-0 bg-[#69383d] flex flex-auto justify-center text-white rounded-lg mt-1 mb-0.5 mr-4 "
                                        [ngClass]="{'bg-[#69383d]': !checkMinima(item), 'bg-[#425c5b]': checkMinima(item)}"
                                        [innerHTML]="'minima-order-basket' | transloco: {AMOUNT: minimaOrder(item) | expeCurrency}">

                                    </div>

                                    <div *ngIf="maximaOrder(item) !== null"
                                        class=" text-[11px] lined-clamp-1 py-0 bg-[#69383d] flex flex-auto justify-center text-white rounded-lg mt-0.5 mr-4"
                                        [ngClass]="{'bg-[#69383d]': !checkMaxima(item), 'bg-[#425c5b]': checkMaxima(item)}"
                                        [innerHTML]="'maxima-order-basket' | transloco: {AMOUNT: maximaOrder(item) | expeCurrency}">

                                    </div>
                                    <ng-container *ngFor="let detail of item.details">
                                        <div class="line-clamp-4">
                                            <div class="flex flex-auto py-1 pr-1">
                                                <div class="flex flex-auto items-center text-sm ellipsis w-[110px] ">
                                                    <div class="truncate">
                                                        <u>Ref</u>: {{detail?.offer?.product?.local_reference}}
                                                        <br />
                                                        <strong>{{detail?.offer?.product?.label}}</strong>
                                                    </div>
                                                </div>

                                                <div class="flex shrink-0 items-center justify-end content-center font-bold"
                                                    style="width: 60px">
                                                    <span class="text-sm">
                                                        {{"basket.qty-in-basket" | transloco: {QTY: detail?.qty} }}</span>
                                                    <!--
                                            <cart-management [isSmall]="true" [offer]="detail?.offer"></cart-management>                                            
                                            -->
                                                </div>

                                                <div class="flex shrink-0 items-center justify-end content-center "
                                                    style="width: 65px">
                                                    <span
                                                        class="text-sm">{{(detail?.price * detail?.qty) | expeCurrency}}</span>
                                                </div>
                                                <div class="flex shrink-0 items-center justify-center content-center">
                                                    <button mat-icon-button [disabled]="hashSession !== null"
                                                        (mouseenter)="showPopover($event)" (mouseleave)="hidePopover()"
                                                        (click)="removeItem(detail.offer.id, detail.offer.product.id, detail.offer.hash)">
                                                        <mat-icon class="icon-size-4 text-current"
                                                            [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                                                    </button></div>
                                            </div>
                                        </div>
                                        <mat-divider></mat-divider>
                                    </ng-container>

                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <div class="w-full my-4 px-8">
                        <button class="expepharma-mat-button-large w-full drop-shadow-md hover:drop-shadow-xl"
                            mat-flat-button [color]="'primary'" type="button" [routerLink]="['secure/pharma/full-cart']"
                            (click)="closePanel()">
                            <span>
                                {{"basket.see-details-cart" | transloco }}
                            </span>
                        </button>
                    </div>
                </ng-container>
                <mat-divider></mat-divider>
                <ng-container *ngIf="itemsThreshold.length > 0">
                    <p class="font-semibold mt-4 text-center"
                        [innerHTML]="'basket.threshold-product-title' | transloco: {AMOUNT: calculateAmount(EnumBasketType.THRESHOLD) | expeCurrency}">
                    </p>
                    <ng-container *ngFor="let item of itemsThreshold; trackBy: trackByFn">
                        <div class="flex group hover:bg-gray-50">
                            <div class="flex flex-auto py-5 pl-6">
                                <div class="flex flex-col flex-auto">
                                    <div
                                        class="font-semibold lined-clamp-1 px-2 py-2 bg-[#232c33] rounded-lg mr-4 text-white flex flex-auto justify-start ">
                                        <img src="assets/images/flags/FR.svg" alt="country" class="mr-2 rounded"
                                            style="width: 20px; height: 16px" />
                                        {{item?.user?.pseudonyme || "USER_ANONYME"}}
                                    </div>

                                    <ng-container *ngFor="let detail of item.details">
                                        <div class="line-clamp-4">
                                            <div class="flex flex-auto py-1 pr-1">
                                                <div class="flex flex-auto items-center text-sm ellipsis">
                                                    <div>
                                                        <u>Ref</u>: {{detail?.offer?.product?.local_reference}}
                                                        <br />
                                                        <strong>{{detail?.offer?.product?.label}}</strong>
                                                    </div>
                                                </div>

                                                <div class="flex shrink-0 items-center justify-end content-center font-bold"
                                                    style="width: 60px">

                                                    {{"basket.qty-in-basket" | transloco: {QTY: detail?.qty} }}
                                                    <!--
                                                      <cart-management [isSmall]="true" [offer]="detail?.offer"></cart-management>                                            
                                                      -->
                                                </div>

                                                <div class="flex shrink-0 items-center justify-end content-center"
                                                    style="width: 65px">
                                                    <span
                                                        class="text-sm">{{(detail?.price * detail?.qty) | expeCurrency}}</span>
                                                </div>
                                                <!--
                                                <div class="flex shrink-0 items-center justify-center content-center">
                                                  
                                                  <button mat-icon-button
                                                        (click)="removeItem(detail.offer.id, detail.offer.product.id, detail.offer.hash)">
                                                        <mat-icon class="icon-size-4 text-current"
                                                            [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                                                    </button>
                                                  
                                                    </div>-->
                                            </div>
                                        </div>
                                        <div class="line-clamp-4">
                                            <div class="flex flex-auto">
                                                <div class="flex flex-auto items-center text-sm ellipsis justify-center content-center text-center pr-4"
                                                    style="color: rgba(0,0,0,0.4)">
                                                    {{"basket.deadline-threshold-explanation" | transloco: {DATE: transformDate(detail?.offer.end_offer)} }}
                                                </div>
                                            </div>
                                        </div>
                                        <mat-divider></mat-divider>
                                    </ng-container>

                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>

        </ng-container>


    </div>

</ng-template>


<ng-template #noClassicItem>
    <div class="relative flex flex-col flex-auto sm:max-h-120 overflow-y-auto bg-card">
        <div class="flex flex-col flex-auto items-center justify-center justify-center py-12 px-8">
            <div class="font-semibold tracking-tight text-center">
                {{"basket.no-item-classic" | transloco}}</div>
        </div>
    </div>
</ng-template>

<ng-template #noThresholdItem>
    <div class="relative flex flex-col flex-auto sm:max-h-120 overflow-y-auto bg-card">
        <div class="flex flex-col flex-auto items-center justify-center justify-center py-12 px-8">
            <div class="font-semibold tracking-tight text-center">
                {{"basket.no-item-threshold" | transloco}}</div>
        </div>
    </div>
</ng-template>



<ng-template #noBasket>
    <div class="relative flex flex-col flex-auto sm:max-h-120 overflow-y-auto bg-card">
        <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
            <div
                class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100 dark:bg-primary-600">
                <mat-icon class="text-primary-700 dark:text-primary-50" [svgIcon]="'heroicons_outline:shopping-cart'">
                </mat-icon>
            </div>
            <div class="mt-5 text-2xl font-semibold tracking-tight">
                {{"basket.no-item" | transloco}}</div>
        </div>
    </div>
</ng-template>
