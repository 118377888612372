import { IUser } from 'app/modules/admin/common/settings/users/users.types';
import { IProduct } from '../product/product.types';
import { ILegal, TTypeAccount } from '../user/user.types';
import { IPagination } from 'app/core/global.types';
import {
    EnumDispatch,
    EnumOrderStatus,
} from 'app/modules/admin/wholesale/orders/orders.types';

export enum ShelflifeType {
    DAY = 'day',
    TIME = 'timestamp',
}

export enum EnmumMarketplaceType {
    QUOTATION = 'quotation',
    PROPOSAL = 'proposal',
    FIXED = 'fixed',
}

export enum StatusQuotation {
    CLOSED = 'closed',
    // VALIDATED = 'validated',
    PROGRESS = 'progress',
    CANCELED = 'canceled',
}

export enum EnumStatusDetailsQuotation {
    VALIDATED = 'validated',
    ACCEPTED = 'accepted',
    PROGRESS = 'progress',
    STOPPED = 'stopped',
    CANCELED = 'canceled',
    REFUSED = 'refused',
    REJECTED = 'rejected',
    PENDING = 'pending',
}

export interface IHttpQuotation extends IPagination {
    data: IQuotation[];
}

export interface IQuotation {
    id?: number;
    hash?: string;
    user_id: string;
    product_id: string;
    qty: number;
    transport_prestation: boolean;
    min_batch: number;
    shelflife_type: ShelflifeType;
    shelflife: string;
    is_multiple: boolean;
    count_multiple: number;
    insurance: boolean;
    status: StatusQuotation;
    offer_status?: EnumStatusDetailsQuotation;
    product?: IProduct;
    count_negoces?: number;
    details?: IDetailsQuotation[];
    negociation?: IDetailsQuotation;
    validate_offer?: IDetailsQuotation;
    price?: number;
    type?: EnmumMarketplaceType;
    rating?: number;
    company?: ILegal;
    account?: TTypeAccount;
    dispatch?: EnumDispatch;
    order?: {
        hash: string;
        status: EnumOrderStatus;
    };
}

export interface IDetailsQuotation {
    id: number;
    hash: string;
    market_id: number;
    seller: IUser;
    price_seller: number;
    price_buyer: number;
    qty: number;
    transport_prestation: number;
    min_batch: number;
    shelflife_type: ShelflifeType;
    shelflife: string;
    is_multiple: boolean;
    count_multiple: number;
    insurance: boolean;
    status: EnumStatusDetailsQuotation;
    created_at: string;
    updated_at: string;
    rating?: number;
    company?: ILegal;
    account?: TTypeAccount;
}

export interface IRealtimeMarketplace {
    status: 'new' | 'update';
    type: 'buy' | 'sale';
    hash: string;
    hash_details?: string;
    user_id: string;
}
