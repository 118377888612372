import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpepharmaCardComponent } from '@expepharma/components/card/card.component';

@NgModule({
    declarations: [ExpepharmaCardComponent],
    imports: [CommonModule],
    exports: [ExpepharmaCardComponent],
})
export class ExpepharmaCardModule {}
