import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartManagementComponent } from './cart-management.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { ExpepharmaAlertModule } from '@expepharma/components/alert';
import { ExpepharmaPopoverModule } from 'app/core/popover/popover.module';
import { SharedModule } from 'app/shared/shared.module';
import { WarningThresholdComponent } from './warning-threshold/warning-threshold.component';

@NgModule({
    declarations: [CartManagementComponent, WarningThresholdComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedModule,
        MatIconModule,
        MatTooltipModule,
        MatSelectModule,
        TranslocoModule,
        ExpepharmaAlertModule,
        ExpepharmaPopoverModule,
    ],
    exports: [CartManagementComponent],
})
export class ExpepharmaCartManagementModule {}
