import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BasketComponent } from 'app/layout/common/basket/basket.component';
import { SharedModule } from 'app/shared/shared.module';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';
import { MatDividerModule } from '@angular/material/divider';
import { ExpepharmaCartManagementModule } from 'app/modules/admin/common/components/offers-card/cart-management/cart-management.module';
import { ModalWarningComponent } from './modal-warning/modal-warning.component';
import { ExpepharmaPopoverModule } from 'app/core/popover/popover.module';
import { ExpepharmaDisplayCustomerModule } from 'app/modules/admin/common/components/display-customer/display-customer.module';

@NgModule({
    declarations: [BasketComponent, ModalWarningComponent],
    imports: [
        RouterModule,
        OverlayModule,
        PortalModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        SharedModule,
        TranslocoModule,
        MatDividerModule,
        ExpepharmaCartManagementModule,
        ExpepharmaPopoverModule,
        ExpepharmaDisplayCustomerModule,
    ],
    exports: [BasketComponent],
})
export class BasketModule {}
