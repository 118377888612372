<div class="flex flex-col max-w-240 max-h-screen -m-6">

    <div class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto">
        <div class="flex mb-4">
            <div class="w-full text-center text-2xl"
                [innerHTML]="'basket.product-soon-expired' | transloco: {PRODUCT: item.offer.product.label, QTY: item.qty}">
            </div>
        </div>

        <div class="flex mb-4">
            <div class="w-full flex justify-center">
                <button
                    class="mat-mdc-button expepharma-mat-button-large w-full transition duration-150 ease-in-out hover:scale-95 hover:drop-shadow-xl hover:bg-[#425c5b] bg-[#5a7d7c] text-white"
                    type="button" mat-flat-button [disabled]="isDisabled"
                    (click)="increaseDeadline(_translocoService.translate('basket.product-error-deadline-update'))">
                    <span>
                        {{"basket.increase-expiration" | transloco}}
                    </span>
                </button>

            </div>
        </div>
        <div class="flex mb-4">
            <div class="w-full flex justify-center">
                <button
                    class="mat-mdc-button expepharma-mat-button-large w-full transition duration-150 ease-in-out hover:scale-95 hover:drop-shadow-xl hover:bg-[#69383d] bg-[#904e55] text-white"
                    type="button" mat-flat-button [disabled]="isDisabled"
                    (click)="removeItem(item.offer.id, item.offer.product_id, item.offer.hash)">
                    <span>
                        {{"basket.remove-btn-modal" | transloco}}
                    </span>
                </button>

            </div>
        </div>
        <div class="flex mb-4">
            <div class="w-full flex justify-center">
                <button
                    class="mat-mdc-button expepharma-mat-button-large w-full transition duration-150 ease-in-out hover:scale-95 hover:drop-shadow hover:bg-[#f3f3f3] bg-[#fff] text-black"
                    type="button" mat-flat-button [disabled]="isDisabled" (click)="closeModal()">
                    <span>
                        {{"basket.not-increase-time" | transloco}}
                    </span>
                </button>

            </div>
        </div>
    </div>
</div>
