import { Injectable, Injector, OnDestroy, Type } from '@angular/core';
import {
    BehaviorSubject,
    Observable,
    Subject,
    combineLatest,
    distinctUntilChanged,
    filter,
    map,
    of,
    switchMap,
    take,
    takeUntil,
    tap,
    throwError,
} from 'rxjs';
import { IFilters } from './filters.types';
import { NavigationStart, Router } from '@angular/router';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { LocalStorageService } from 'ngx-webstorage';
import { deepCompare } from 'app/core/global.helpers';

@Injectable({
    providedIn: 'root',
})
export class FilterService implements OnDestroy {
    private _filtersInitialized = new BehaviorSubject<boolean>(false);
    private nameFilter: string = null;
    private _filters: BehaviorSubject<IFilters> = new BehaviorSubject<IFilters>(
        {}
    );
    private _externalServiceInitialized = new BehaviorSubject<boolean>(false);
    private externalService: any = null;
    private methodExternalService: string = null;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private _resolverInProgress = new BehaviorSubject<boolean>(false);
    private blockEmission: boolean = false;

    constructor(
        private router: Router,
        private injector: Injector,
        private _localStorageService: LocalStorageService
    ) {
        /**
         * Souscrit aux événements de navigation du routeur
         * Filtre pour ne garder que les événements NavigationStart
         * Prend le premier événement NavigationStart
         * Récupère les paramètres 'filters' dans l'URL
         * Initialise les filtres avec ces paramètres
         * Définit _filtersInitialized sur true pour indiquer que les filtres sont initialisés
         */
        // this.router.events
        //     .pipe(
        //         filter((event) => event instanceof NavigationStart),
        //         takeUntil(this._unsubscribeAll),
        //         take(1),
        //         map((event: NavigationStart) => {
        //             const url = event.url;
        //             const urlSearchParams = new URLSearchParams(
        //                 url.split('?')[1]
        //             );
        //             const params = urlSearchParams.get('filters');
        //             this._filtersInitialized.next(true);
        //             return params;
        //         }),
        //         switchMap((params) =>
        //             combineLatest([this._externalServiceInitialized]).pipe(
        //                 filter(
        //                     ([_externalServiceInitialized]) =>
        //                         _externalServiceInitialized === true
        //                 ),
        //                 takeUntil(this._unsubscribeAll),
        //                 take(1),
        //                 tap((e) => {
        //                     return params;
        //                 })
        //             )
        //         )
        //     )
        //     .subscribe((params) => {
        //         this.initFilters({ filters: params });
        //     });
        /**
         * Souscrit aux changements de filtres et appelle le service externe pour appliquer les filtres si disponible
         *
         * Combine les dernières valeurs de this._filters et this._filtersInitialized,
         * filtre pour attendre que les filtres soient initialisés
         * distinctUntilChanged pour éviter les appels en double quand les filtres n'ont pas changé
         * map pour ne garder que les filtres
         * takeUntil pour arrêter l'abonnement quand le composant est détruit
         *
         * Appelle this.webservice avec les filtres chiffrés si un service externe est configuré
         */
        combineLatest([
            this._filters,
            this._filtersInitialized,
            this._externalServiceInitialized,
            this._resolverInProgress,
            this.router.events,
        ])
            .pipe(
                takeUntil(this._unsubscribeAll),
                distinctUntilChanged((prev, curr) => {
                    return deepCompare(prev[0], curr[0]);
                }),
                filter(
                    ([
                        filters,
                        _filtersInitialized,
                        _externalServiceInitialized,
                        _resolverInProgress,
                        _router,
                    ]) => {
                        return (
                            _filtersInitialized === true &&
                            _externalServiceInitialized === true &&
                            _resolverInProgress === false &&
                            !this.blockEmission
                        );
                    }
                ),
                switchMap(([filters]) => of(filters))
            )
            .subscribe((filters) => {
                this.webservice({
                    filters: AuthUtils.fullAesEncode(filters),
                }).subscribe();
            });
    }

    init(): Observable<any> {
        return this.router.events.pipe(
            filter((event) => event instanceof NavigationStart),
            take(1),
            map((event: NavigationStart) => {
                const url = event.url;
                const urlSearchParams = new URLSearchParams(url.split('?')[1]);
                const params = urlSearchParams.get('filters');
                this._filtersInitialized.next(true);
                return params;
            }),
            switchMap((params) =>
                combineLatest([this._externalServiceInitialized]).pipe(
                    filter(
                        ([_externalServiceInitialized]) =>
                            _externalServiceInitialized === true
                    ),
                    take(1),
                    tap((params) => {
                        this.initFilters({ filters: params });
                    })
                )
            )
        );
    }

    /**
     * Fonction appelée lorsque le composant est détruit.
     *
     * Déclenche la fermeture de l'Observable this._unsubscribeAll
     * pour nettoyer tous les abonnements du composant.
     */
    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    /**
     * Permet de changer le service externe utilisé pour les filtres.
     *
     * @param service - Le service à utiliser
     * @param method - La méthode du service à appeler
     */
    switchService(service: Type<any>, method: string, isSetFilters = true) {
        this.externalService = this.injector.get(service);
        this.methodExternalService = method;
        this.blockEmission = true;
        this._filters.next({});
        this.blockEmission = false;
        this._externalServiceInitialized.next(true);
        this.setFilters(this.getFiltersFromLocalStorage(), false);
    }

    /**
     * Appelle le service externe pour appliquer les filtres
     */
    webservice(params, isFirst = false) {
        if (isFirst) {
            this._resolverInProgress.next(false);
        }

        return this.externalService[this.methodExternalService](params);
    }

    /**
     * Récupère les filtres courants
     */
    get filters$(): Observable<IFilters> {
        return this._filters
            .asObservable()
            .pipe(filter(() => !this.blockEmission));
    }

    /**
     * Récupère les filtres courants
     */
    getFilters() {
        return this._filters.value;
    }

    /**
     * Récupère les filtres courants sous forme de tableau
     *
     * @returns Tableau des filtres courants
     */
    getFiltersArray() {
        return Object.entries(this._filters.value).map((item) => {
            return {
                key: item[0],
                value: item[1],
            };
        });
    }

    /**
     * Définit les filtres courants.
     *
     * @param filters - Les filtres à définir
     * @param updateUrl - Si vrai, met à jour l'URL avec les nouveaux filtres
     */
    setFilters(filters: IFilters, updateUrl: boolean = true) {
        const currentFilters = this.getFilters();
        const updatedFilters = { ...currentFilters, ...filters };
        this._filters.next(updatedFilters);
        const hash = AuthUtils.fullAesEncode(updatedFilters);
        this.saveFiltersToLocalStorage(hash);
        if (updateUrl) {
            this.updateUrl(hash);
        }
    }

    /**
     * Initialise les filtres
     */
    initFilters(params) {
        let filters = this.getFiltersFromUrl(params);
        if (this.isFiltersEmpty(filters)) {
            const localFilters = this.getFiltersFromLocalStorage();
            this.setFilters(localFilters, false);
        } else {
            this.setFilters(filters, false);
        }
    }

    /**
     * Attend que l'initialisation des filtres soit terminée avant de continuer.
     *
     * Cette méthode renvoie un Observable qui émet true lorsque les filtres
     * ont fini de s'initialiser. Elle permet d'attendre que les filtres soient prêts
     * avant d'effectuer une opération qui en dépend.
     */
    waitForFiltersInitialization(
        service: Type<any>,
        method: string,
        name: string
    ): Observable<boolean> {
        this._resolverInProgress.next(true);
        // if (this.methodExternalService !== null) {
        //     this._filters.next({});
        // }
        // this._wsCallFirst.next(false);

        this.nameFilter = name;

        return this._filtersInitialized.asObservable().pipe(
            switchMap((initialized) => {
                if (initialized) {
                    return of(true).pipe(
                        // switchMap(() => )
                        tap(() => {
                            this.switchService(service, method);
                        })
                    );
                } else {
                    return throwError(() => new Error('Initialization failed'));
                }
            })
        );
    }

    /**
     * Récupère les filtres à partir de l'URL.
     *
     * @param params - Les paramètres de l'URL
     * @returns Les filtres extraits de l'URL
     */
    getFiltersFromUrl(params): IFilters {
        if (!params || !params.filters) {
            return {};
        } else {
            const hash = params['filters'];
            const array = AuthUtils.fullAesDecode(hash);

            return array === null ? {} : array;
        }
    }

    /**
     * Met à jour l'URL avec les filtres courants.
     */
    updateUrl(hash) {
        if (hash === null) {
            this.router.navigate([], {
                queryParams: {},
            });
        } else {
            this.router.navigate([], {
                queryParams: {
                    filters: hash,
                },
            });
        }
    }

    isFiltersEmpty(filters: IFilters): boolean {
        return (
            Object.keys(filters).length === 0 && filters.constructor === Object
        );
    }

    saveFiltersToLocalStorage(hash: string) {
        if (this.externalService) {
            this._localStorageService.store(this.getGoodNameStorage(), hash);
        }
    }

    /**
     * Charge les filtres depuis le localStorage associés au service externe.
     * @returns Les filtres chargés ou null si aucun filtre n'est stocké.
     */
    getFiltersFromLocalStorage(): IFilters | null {
        if (this.externalService) {
            const filtersString = this._localStorageService.retrieve(
                this.getGoodNameStorage()
            );

            if (filtersString) {
                const array = AuthUtils.fullAesDecode(filtersString);
                return array === null ? {} : array;
            }
        }

        return {};
    }

    getGoodNameStorage(): string {
        if (this.nameFilter !== null) {
            return `savedFilters_${this.nameFilter}`;
        } else {
            if (this.externalService) {
                return `savedFilters_${this.externalService.constructor.name}`;
            } else {
                return `savedFilters_default`;
            }
        }
    }

    resetFilters() {
        if (this.externalService) {
            this._localStorageService.clear(this.getGoodNameStorage());
        }
        this._filters.next({});
        this.updateUrl(null);
    }

    resetUrl() {
        this.updateUrl(null);
    }

    refreshData(): Observable<any> {
        if (this.externalService) {
            const currentFilters = this.getFilters();

            return this.webservice({
                filters: AuthUtils.fullAesEncode(currentFilters),
            });
        } else {
            return of(true);
        }
    }
}
