import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ExpepharmaScrollbarModule } from '@expepharma/directives/scrollbar/public-api';
import { ExpepharmaHorizontalNavigationBasicItemComponent } from '@expepharma/components/navigation/horizontal/components/basic/basic.component';
import { ExpepharmaHorizontalNavigationBranchItemComponent } from '@expepharma/components/navigation/horizontal/components/branch/branch.component';
import { ExpepharmaHorizontalNavigationDividerItemComponent } from '@expepharma/components/navigation/horizontal/components/divider/divider.component';
import { ExpepharmaHorizontalNavigationSpacerItemComponent } from '@expepharma/components/navigation/horizontal/components/spacer/spacer.component';
import { ExpepharmaHorizontalNavigationComponent } from '@expepharma/components/navigation/horizontal/horizontal.component';
import { ExpepharmaVerticalNavigationAsideItemComponent } from '@expepharma/components/navigation/vertical/components/aside/aside.component';
import { ExpepharmaVerticalNavigationBasicItemComponent } from '@expepharma/components/navigation/vertical/components/basic/basic.component';
import { ExpepharmaVerticalNavigationCollapsableItemComponent } from '@expepharma/components/navigation/vertical/components/collapsable/collapsable.component';
import { ExpepharmaVerticalNavigationDividerItemComponent } from '@expepharma/components/navigation/vertical/components/divider/divider.component';
import { ExpepharmaVerticalNavigationGroupItemComponent } from '@expepharma/components/navigation/vertical/components/group/group.component';
import { ExpepharmaVerticalNavigationSpacerItemComponent } from '@expepharma/components/navigation/vertical/components/spacer/spacer.component';
import { ExpepharmaVerticalNavigationComponent } from '@expepharma/components/navigation/vertical/vertical.component';

@NgModule({
    declarations: [
        ExpepharmaHorizontalNavigationBasicItemComponent,
        ExpepharmaHorizontalNavigationBranchItemComponent,
        ExpepharmaHorizontalNavigationDividerItemComponent,
        ExpepharmaHorizontalNavigationSpacerItemComponent,
        ExpepharmaHorizontalNavigationComponent,
        ExpepharmaVerticalNavigationAsideItemComponent,
        ExpepharmaVerticalNavigationBasicItemComponent,
        ExpepharmaVerticalNavigationCollapsableItemComponent,
        ExpepharmaVerticalNavigationDividerItemComponent,
        ExpepharmaVerticalNavigationGroupItemComponent,
        ExpepharmaVerticalNavigationSpacerItemComponent,
        ExpepharmaVerticalNavigationComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        ExpepharmaScrollbarModule,
    ],
    exports: [
        ExpepharmaHorizontalNavigationComponent,
        ExpepharmaVerticalNavigationComponent,
    ],
})
export class ExpepharmaNavigationModule {}
