import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ExpepharmaConfirmationModule } from '@expepharma/services/confirmation';
import { ExpepharmaLoadingModule } from '@expepharma/services/loading';
import { ExpepharmaMediaWatcherModule } from '@expepharma/services/media-watcher/media-watcher.module';
import { ExpepharmaPlatformModule } from '@expepharma/services/platform/platform.module';
import { ExpepharmaSplashScreenModule } from '@expepharma/services/splash-screen/splash-screen.module';
import { ExpepharmaUtilsModule } from '@expepharma/services/utils/utils.module';

@NgModule({
    imports: [
        ExpepharmaConfirmationModule,
        ExpepharmaLoadingModule,
        ExpepharmaMediaWatcherModule,
        ExpepharmaPlatformModule,
        ExpepharmaSplashScreenModule,
        ExpepharmaUtilsModule,
    ],
    providers: [
        {
            // Disable 'theme' sanity check
            provide: MATERIAL_SANITY_CHECKS,
            useValue: {
                doctype: true,
                theme: false,
                version: true,
            },
        },
        {
            // Use the 'fill' appearance on Angular Material form fields by default
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'fill',
            },
        },
    ],
})
export class ExpepharmaModule {
    /**
     * Constructor
     */
    constructor(@Optional() @SkipSelf() parentModule?: ExpepharmaModule) {
        if (parentModule) {
            throw new Error(
                'ExpepharmaModule has already been loaded. Import this module in the AppModule only!'
            );
        }
    }
}
