import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';
import { DocumentService } from 'app/core/document/document.service';
import { IDocsList, IDocsUser } from 'app/core/document/document.types';
import { Observable, Subject, combineLatest, map, takeUntil } from 'rxjs';
import {
    NgForm,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { requiredFileType, requiredFileSize } from 'app/shared/helper';
import { UserService } from 'app/core/user/user.service';
import { Router } from '@angular/router';
import { AlertService } from 'app/core/alerts/alert.service';

@Component({
    selector: 'step-accountvalidation-account',
    templateUrl: './validation-account.component.html',
    styleUrls: ['./validation-account.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationAccountComponent {
    @ViewChild('stepNgForm') stepNgForm: NgForm;
    @ViewChild('suscriptionNgForm') suscriptionNgForm: NgForm;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    docsUser$: Observable<IDocsUser[]>;
    docsList: IDocsList[] = [];
    document_file: File = null;
    stepForm: UntypedFormGroup;
    suscriptionForm: UntypedFormGroup;
    isLoadingFile: boolean = false;
    formSubmitAttempt: boolean = false;
    isConformityUser: boolean = false;
    isFinalLoading: boolean = false;
    /**
     * Constructor
     */
    constructor(
        public matDialogRef: MatDialogRef<ValidationAccountComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _documentService: DocumentService,
        private _formBuilder: UntypedFormBuilder,
        private _translocoService: TranslocoService,
        private _matDialog: MatDialog,
        private _changeDetectorRef: ChangeDetectorRef,
        private _userService: UserService,
        private _router: Router,
        private _alertService: AlertService
    ) {
        this._documentService
            .getListDocumentsByType(data.type)
            .pipe(
                takeUntil(this._unsubscribeAll),
                map((doc) => {
                    return doc.sort((a, b): any => {
                        if (a.is_mandatory > b.is_mandatory) {
                            return -1;
                        }
                        if (a.is_mandatory < b.is_mandatory) {
                            return 1;
                        }
                        return 0;
                    });
                })
            )
            .subscribe((docs) => {
                this.docsList = docs;
            });

        this._documentService
            .getListDocumentsByUser()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe();
    }

    ngOnInit(): void {
        this.docsUser$ = this._documentService.docsUser$;

        combineLatest(
            this._documentService.docsList$,
            this._documentService.docsUser$
        )
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((list) => {
                const docsList = list[0];
                const docsUser = list[1];

                const listMandatory = docsList.filter(
                    (e) => e.is_mandatory === true
                );
                const listUserMandatory = listMandatory.filter((e) => {
                    return docsUser.find((f) => f.doc_type === e.doc_type) !==
                        undefined
                        ? true
                        : false;
                });

                this.isConformityUser =
                    listMandatory.length === listUserMandatory.length &&
                    listUserMandatory.length !== 0
                        ? true
                        : false;

                setTimeout(() => {
                    this._changeDetectorRef.markForCheck();
                }, 100);
            });

        this.stepForm = this._formBuilder.group({
            type: [null],
            document_file: [null, [Validators.required]],
            value: [''],
        });

        this.suscriptionForm = this._formBuilder.group({
            suscription: ['base'],
            type: ['monthly'],
            account: [this.data.type],
        });
    }

    /**
     * Control method after sending license file
     * @param event: Native params
     */
    onFileSelected(event: Event) {
        const element = event.currentTarget as HTMLInputElement;
        const files: FileList = element.files;

        if (files.length == 1) {
            this.document_file = files[0];
            if (!requiredFileType(['pdf'], this.document_file)) {
                this.stepForm.get('document_file').setErrors({
                    type: true,
                });
            }

            if (!requiredFileSize(1000000, this.document_file)) {
                this.stepForm.get('document_file').setErrors({
                    size: true,
                });
            }
        }
    }

    getFileName(): string {
        return this.document_file === null
            ? this._translocoService.translate('no-file-select')
            : '<strong>' + this.document_file.name + '</strong>';
    }

    resetFormStep(): void {
        this.stepNgForm.resetForm();
    }

    sendDocument(): void {
        this.isLoadingFile = true;
        this.formSubmitAttempt = true;

        if (this.stepForm.invalid) {
            this.isLoadingFile = false;
            return;
        }
        this.stepForm.disable();
        this._documentService
            .sendDocument(this.document_file, {
                ...this.stepForm.value,
            })
            .subscribe((e) => {
                this.formSubmitAttempt = false;
                this.isLoadingFile = false;
                this.stepNgForm.resetForm();
                this.stepForm.enable();
                this.document_file = null;
            });
    }

    validateAccount(): void {
        this.isFinalLoading = true;

        if (this.suscriptionForm.invalid) {
            this.isFinalLoading = false;
            return;
        }

        this.suscriptionForm.disable();
        this._userService
            .addSuscription({
                ...this.suscriptionForm.value,
            })
            .subscribe((e) => {
                this.isFinalLoading = false;
                this.suscriptionNgForm.resetForm();
                this.suscriptionForm.enable();
                this._alertService
                    .newAlert({
                        appearance: 'fill',
                        dismissed: false,
                        showIcon: true,
                        type: 'success',
                        position: 'top-center',
                        message: this._translocoService.translate(
                            'registration-success'
                        ),
                        clearTime: 100000,
                    })
                    .subscribe();
                this.closeModal();
            });
    }

    closeModal(): void {
        this.matDialogRef.close();
    }

    removeDocument(doc: IDocsUser): void {
        this.isLoadingFile = true;
        this._documentService.removeDocument(doc).subscribe((e) => {
            this.isLoadingFile = false;
        });
    }

    getSelectedValue(): string {
        if (this.stepForm.get('type').value === null) {
            return null;
        }

        const value = this.docsList.find(
            (e) => e.doc_type === this.stepForm.get('type').value
        );

        if (!value) {
            return null;
        }

        return `<span class="mdc-list-item__primary-text font-gilroy-light text-sm">${this._translocoService.translate(
            value.doc_type
        )} <span
        class="m-1 py-0.5 px-2.5 rounded-full text-sm font-medium whitespace-nowrap text-red-800 bg-red-100 ${
            value.is_mandatory === false ? 'hidden' : null
        }"><b>${this._translocoService.translate(
            'mandatory'
        )}</b></span></span>`;
    }

    downloadDocument(filename: string) {
        this._documentService.downloadDocument(filename).subscribe((path) => {
            window.open(path, '_blank');
        });
    }

    ngOnDestroy() {
        this._unsubscribeAll.complete();
    }
}
