import { Injectable } from '@angular/core';
import { ExpepharmaApiService } from '@expepharma/lib/api';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class NavigationApi {
    /**
     * Constructor
     */
    constructor(private _expepharmaMockApiService: ExpepharmaApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Navigation - GET
        // -----------------------------------------------------------------------------------------------------
        this._expepharmaMockApiService.onGet(
            environment.apiUrl + '/v1/oauth/navigation'
        );
    }
}
