import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, Observable, ReplaySubject, tap } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class HistoryService {
    private _currentSlug: BehaviorSubject<string> = new BehaviorSubject('/');
    private history = [];

    /**
     * Constructor
     */
    constructor(private _router: Router) {}

    get currentSlug$(): Observable<string> {
        return this._currentSlug.asObservable();
    }

    updateCurrentSlug(slug: string) {
        this._currentSlug.next(slug);
    }

    init() {
        this._router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
                this.history = [...this.history.reverse(), urlAfterRedirects]
                    .reverse()
                    .slice(0, 10);
            });
    }

    public getHistory(): string[] {
        return this.history;
    }

    public getPreviousUrl(): string {
        return this.history[this.history.length - 2] || '/index';
    }
}
